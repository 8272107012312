import { $authHost } from "./";

export const defaultConfig = {
  headers: { "Content-Type": "application/json" },
};

export function CRUD(baseUrl) {
  const API = "/api/v1";

  return {
    search: function (params = {}) {
      return parseResponse(
        $authHost.post(`${API}/${baseUrl}/query`, params, defaultConfig)
      );
    },
    post: function (params = {}, query) {
      let url = `${API}/${baseUrl}`;
      if (query)
        url += query;
      return parseResponse(
        $authHost.post(url, params, defaultConfig)
      );
    },
    get: function (id) {
     return parseResponse($authHost.get(`${API}/${baseUrl}/${id}`));
    }, 
    getContracts: function (id) {
      return parseResponse($authHost.get(`${API}/${baseUrl}`));
     },    
    getResult: function (id) {
      return parseResponse($authHost.get(`${API}/${baseUrl}/result?${id}`));
     },    
    create: function (data) {
      return parseResponse(
         $authHost.post(`${API}/${baseUrl}`, data, defaultConfig)
      );
    },
    edit: function (data) {
      return parseResponse(
        $authHost.put(`${API}/${baseUrl}`, data, defaultConfig)
      );
    },
    delete: function (id) {
      return parseResponse(
        $authHost.delete(`${API}/${baseUrl}`, { data: { id } }, defaultConfig)
      );
    },
    workflowRun: function (id, oldStateId, newStateId) {
      return parseResponse(
        $authHost.post(
          `${API}/${baseUrl}/workflow/run`,
          { id: parseInt(id), oldStateId, newStateId },
          defaultConfig
        )
      );
    },
  };
}

export function parseResponse(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) =>{
        reject(parseError(error))
      } );
  });
}

function parseError(error) {
  return (
    error.response?.data?.message ||
    (error.response?.data?.errors && error.response?.data?.errors?.length
      ? error.response.data.errors.join(", ")
      : error.message)
  );
}
