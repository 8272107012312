import React, {useRef} from 'react';
import {Box} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {contractSignerCRUD} from "../../http/CRUD";
import QRCode from "qrcode";
import {LoadingButton} from "@mui/lab";
import useLoader from "../../hooks/useLoader";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@pred1995/crm-nce-ckeditor';
import juice from 'juice';
import {xml2js} from "xml-js";
import {generateLink} from "../../functions";

const ContractView = ({initialValue, rowsPage, lastSigned}) => {
    const editorRef = useRef(null);
    const [value, setValue] = React.useState(initialValue)

    const styles = `<styles>
:root {
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-todo-list-checkmark-size: 16px;
}

 table {
    border: 1px solid black;
    border-collapse: collapse;
    }
    th, td {
     border: 1px solid black;
    }

/* and so on.. */
</styles>`;

    const {loading, start, stop} = useLoader(false);

    const handleDownload = async () => {
        const html = juice.inlineContent(editorRef.current?.getData(), styles);

        let sourceHTML = `
                <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
                <html xmlns:office="urn:schemas-microsoft-com:office:office"
                      xmlns:word="urn:schemas-microsoft-com:office:word"
                      xmlns="http://www.w3.org/TR/REC-html40">

                <head>
                <style>
                @page Section1 {size:595.45pt 841.7pt; margin:0.5in 0.5in 0.5in 0.5in;mso-page-orientation:portrait;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0; mso-footer: f1; mso-header: h1;}
                        div.Section1 {page:Section1;}

                </style>
                </head>
                <body>

                <div class=Section1>
                ${html}
                </div>
                </body>
                </html>
         `;
        start()
        if (lastSigned && rowsPage.status === 'Подписан') {
            let link = generateLink(rowsPage?.number)

            if (link) {
                const qrData = await QRCode.toDataURL(link.url)

                sourceHTML = `
                <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
                <html xmlns:office="urn:schemas-microsoft-com:office:office"
                      xmlns:word="urn:schemas-microsoft-com:office:word"
                      xmlns="http://www.w3.org/TR/REC-html40">

                <head>
                <style>
                @page Section1 {size:595.45pt 841.7pt; margin:0.5in 0.5in 0.5in 0.5in;mso-page-orientation:portrait;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0; mso-footer: f1; mso-header: h1;}
                        div.Section1 {page:Section1;}

                </style>
                </head>
                <body>

                <div class=Section1>
                ${html}
                </div>
                <p style="margin-top: 200px">Осы құжат "Электрондық құжат және электрондық цифрлық қолтаңба туралы " Қазақстан Республикасының 2003 жылғы 7 қаңтардағы N 370-II Заңы 7 бабының 1 тармағына сәйкес қағаз тасығыштағы құжатпен
            бірдей.</p>
                <p>Данный документ согласно пункту 1 статьи 7 ЗРК от 7 января 2003 года «Об электронном документе и электронной цифровой подписи» равнозначен документу на бумажном носителе.</p>
                <img width="122" height="123" src=${qrData}/>
                </body>
                </html>
         `
                let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
                let fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = source;
                fileDownload.download = `Договор (id: ${rowsPage?.number}).doc`;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            }


        } else {
            let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
            let fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = `Договор (id: ${rowsPage?.number}).doc`;
            fileDownload.click();
            document.body.removeChild(fileDownload);
        }
        stop()
    }



    return (
        <Box sx={{mt: 5}} className="view-contract">
            <CKEditor
                disabled
                id="editor"
                onReady={editor => {
                    editorRef.current = editor
                }}
                editor={ClassicEditor}
                data={value}
            />
            <LoadingButton loading={loading} onClick={handleDownload} startIcon={<ArrowDownwardIcon/>}
                           sx={{marginTop: 3}} size="small" letiant="outlined">Скачать договор</LoadingButton>
        </Box>
    );
}

export default ContractView;
