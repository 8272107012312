import React, {useEffect, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {INVOICE_ADD_ROUTE, INVOICE_ROUTE} from "../../routes/const";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {t} from "i18next";
import moment from "moment";
import {DeleteForeverIconCustom, TableCustom, VisibilityIconCustom} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import {completionActTableRowName} from "../../lang/i18n";
import {changeInvoiceState, getInvoice} from "../../redux/actions/bookkeeping/bookkeepingActions";
import PopupExcelInvoice from "../../components/Popups/PopupExcelInvoice";

function Invoice(props) {
    const history = useHistory()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const dispatch = useDispatch()
    const {invoices, invoicesCountTable} = useSelector((state) => state.bookkeeping);
    const [id, setId] = useState(1)

    const [open, setOpen] = useState(false)

    const rowName = [
        ...completionActTableRowName
    ]

    useEffect(() => {
        (async function () {
            dispatch(getInvoice(page + 1, rowsPerPage))
        }())
    }, [dispatch, page, rowsPerPage]);

    const openHandler = (value, id) => {
        return () => {
            setId(id)
            setOpen(value)
        }
    }

    const changeState = (id) => {
        return () => {
            dispatch(changeInvoiceState(id, 1, 2, page, rowsPerPage))
        }
    }


    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Счета на оплату', link: INVOICE_ROUTE}
            ]}/>
            <Box sx={{margin: '0 0 20px 0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => history.push(INVOICE_ADD_ROUTE)} color="primary"
                        variant="contained">{t("invoice.buttonText")}</Button>
            </Box>
            <TableCustom rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}
                         countRow={invoicesCountTable} name="Счета на оплату" avatars={false} rowName={rowName}
                         rows={invoices && invoices.map(el => {
                             return {
                                 id: el?.id,
                                 startDate: moment(el.startDate).format('LL'),
                                 name: el?.contract?.name,
                                 status: el?.state?.name,
                                 view: <Button onClick={openHandler(true, el?.id)} size="small" variant="contained">Смотреть</Button>,
                                 bookkeeping: el?.state?.id === 1 ? <Button onClick={changeState(el?.id)} size="small" color="warning" variant="contained">Отправить в бухгалтерию</Button> : 'Отправлено'
                             }
                         })} locale="invoices" actions={[
                {icon: <VisibilityIconCustom/>, id: 'VisibilityIcon'},
                {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            <PopupExcelInvoice id={id} open={open} handleClose={() => setOpen(false)}/>
        </Dashboard>
    );
}

export default Invoice;