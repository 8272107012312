import React, {useEffect, useState} from 'react';
import {style} from "../Popups/popup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {
    addContractComment, changeContractState, declineContract,
    getContractFile,
    getContractFileS3
} from "../../redux/actions/contract/contractActions";
import ContractView from "./ContractView";
import TextShared from "../Shared/TextShared";
import Button from "@mui/material/Button";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { json2xml } from 'xml-js';
import {useNCALayerClient} from "../../hooks/ecp/SignHooks";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {LoadingButton} from "@mui/lab";
import useLoader from "../../hooks/useLoader";
import {clientContractCRUD, contractSignerCRUD} from "../../http/CRUD";
import CryptoJs from 'crypto-js/md5';
import {generateLink} from "../../functions";
import useRoles from '../../hooks/useRoles';
window.Buffer = window.Buffer || require("buffer").Buffer;

const PopupContractComment = ({open, lastSigned, handleClose, id, reload, setConfrom, data}) => {
    const [initialValue, setInitialValue] = useState(null)
    const [file, setFile] = useState(null)
    const [comment, setComment] = useState('')
    const {loading, start, stop} = useLoader(false);

    const [ client, ready ] = useNCALayerClient();
    const [ecpContractXml, setEcpContractXml] = useState({});
    const [ecpReady, setEcpReady] = useState(false);
    const [ecpMessage, setEcpMessage] = useState(null);
    const [ecpContractSignedXml, setEcpContractSigned] = useState({});
    const {isClient} = useRoles();

    useEffect(() => {
        if (!client)
            return;

        client.onopen = function() {
            setEcpReady(true);
            setEcpMessage(null);
        }

        client.onclose = function() {
            setEcpReady(false);
            setEcpMessage("Ошибка соединения с провайдером ЭЦП (NCALayer)");
        }

        client.close = function() {
            setEcpContractSigned({})
            setEcpContractXml({})
        }

        client.onmessage = function (msg) {
            console.log(msg)
            if (msg.responseObject)
            setEcpContractSigned(msg.responseObject || {});

        };

    }, [client])

    const dispatch = useDispatch()

    const willSelectEcp = (data, last = false) => {
        let lastData = null;
        if (last) {
            let parser = new DOMParser();
            let serializeData = unescape(encodeURIComponent(data));
            let xmlz  = parser.parseFromString(serializeData, "application/xml");
            lastData = window.btoa((new XMLSerializer()).serializeToString(xmlz));
        }
        const loginXml =  last ?
            '<contract>' +
            '  <signedContract>' +
            '    <base64>' + lastData + '</base64>' +
            '  </signedContract>' +
            '</contract>' :  '<contract>' + data + '</contract>';
        setEcpContractXml(loginXml);

        client.signXml('PKCS12', 'SIGNATURE', loginXml);
    }

    useEffect(() => {
        (async function () {
            const answer = await dispatch(getContractFile(1, 10, {
                filter: {
                    contractId: {
                        operand1: id,
                        operator: "equals"
                    },
                }
            }))
            const {bucketName, key, objectName} = JSON.parse(answer[0]?.s3Path)
            const answerS3 = await dispatch(getContractFileS3(bucketName, key, objectName))
            answerS3 && setInitialValue(answerS3)
        }())
    }, [dispatch, id])

    const handleSign = async () => {
        start()
        debugger;
        if (!isClient()) {
            await dispatch(addContractComment(id, comment, ecpContractXml, ecpContractSignedXml));
        }
            else {
                const request = { id, comment};

                if (ecpContractXml && ecpContractSignedXml) {
                    request.sign = {
                        xml: ecpContractXml,
                        signedXml: ecpContractSignedXml
                    }
                }
               await clientContractCRUD.post(request, '/workflow/sign/run');
        };
        await dispatch(showSuccessSnackbar("Договор согласован!", "success"))
        await reload()
        await setConfrom(false)
        stop()
        handleClose()
    }

    const handleSignEcp = async () => {

        let sourceHTML = `
                <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
                <html xmlns:office="urn:schemas-microsoft-com:office:office"
                      xmlns:word="urn:schemas-microsoft-com:office:word"
                      xmlns="http://www.w3.org/TR/REC-html40">
                
                <head>
                <style>        
                @page Section1 {size:595.45pt 841.7pt; margin:1.0in 1.25in 1.0in 1.25in;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0;}
                        div.Section1 {page:Section1;}
                        @page Section2 {size:841.7pt 595.45pt;mso-page-orientation:landscape;margin:1.25in 1.0in 1.25in 1.0in;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0;}
                        div.Section2 {page:Section2;}
                
                </style>
                </head>
                <body>
                
                <div class=Section2>
                ${initialValue}
                </div>
                </body>
                </html>
         `;

        let file = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);

        let hash = CryptoJs(file).toString();

        const jsonData = JSON.stringify({...data, file: hash});

        if (lastSigned) {
            const data = await contractSignerCRUD.get(lastSigned.id)
            const jsonData = JSON.parse(data?.sign);
            await willSelectEcp(jsonData?.SignedXml, true)
        } else {
            const xml = json2xml(jsonData, { compact: true, spaces: 1 });
            await willSelectEcp(xml);
        }


    }

    const handleDeclinedContract = async () => {
        await dispatch(declineContract(id, comment))
        await dispatch(showSuccessSnackbar("Договор отлонён!", "success"))

        reload()
        handleClose()
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: 900, maxHeight: 800, overflowY: 'scroll'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Согласование договора
                    </Typography>
                    {/* {initialValue ? <ContractView setFile={setFile} initialValue={initialValue}/> :
                        <Typography sx={{marginTop: 4}} id="modal-modal-title" variant="h6" component="h4">
                            Договор не найден
                        </Typography>
                    } */}
                    <TextShared multiline={true} setValue={setComment} value={comment} label="Комментарии"/>
                    {ecpMessage && <p>{ecpMessage}</p>}
                    <Button
                        onClick={handleDeclinedContract}
                        sx={{margin: '10px 0'}} variant="outlined" color="error" startIcon={<DoNotDisturbIcon/>}>
                        Отказать
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={handleSign}
                        disabled={!Object.keys(ecpContractXml)?.length || !Object.keys(ecpContractSignedXml)?.length}
                        sx={{margin: '10px 0 10px 10px'}} color="success" variant="outlined"
                        startIcon={<CompareArrowsIcon/>}>
                        Подписать
                    </LoadingButton>
                    <Button
                            variant="contained"
                            sx={{margin: '10px 0 10px 10px'}}
                            onClick={handleSignEcp}
                    >
                        Выбрать ЭЦП
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}

export default PopupContractComment;
