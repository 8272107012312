import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { FileCard, PopupContract, TableTabLegalClient } from "../index";
import { useDispatch, useSelector } from "react-redux";
import { getOnePerson } from "../../redux/actions/client/personActions";
import { getOneLegalClient } from "../../redux/actions/client/legalClientActions";
import moment from "moment";
import 'moment/locale/ru'
import TableTabPerson from "./TableTabPerson";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TableTabSigners from "./TableTabSigners";
import PopupContractComment from "./PopupContractComment";
import { changeContractState, getOneContract } from "../../redux/actions/contract/contractActions";
import { checkRoles } from "../../functions/checkRoles";
import { useNCALayerClient } from "../../hooks/ecp/SignHooks";
import { clientContractCRUD, contractRequestCRUD, contractSignerCRUD } from "../../http/CRUD";
import { LoadingButton } from "@mui/lab";
import useLoader from "../../hooks/useLoader";
import { showSuccessSnackbar } from "../../redux/actions/userActions";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { CONTRACT_REQUESTS_ROUTE } from "../../routes/const";
import { $authHost } from "../../http";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import GenerateDocument from '../../screens/Contract/Pages/Add/GenerateDocument';
import { ClientPdf } from '../../screens/ClientContracts/ClientPdf';

window.Buffer = window.Buffer || require("buffer").Buffer;

moment.locale('ru')

function TableTabClient({ data, rowsPage, locale, reload, reloadValue }) {

    const { t } = useTranslation();
    const [client, setClient] = useState([])
    const dispatch = useDispatch()
    const [contractRequest, setContractRequest] = useState([])
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)
    const [openComment, setOpenComment] = useState(false)
    const [protocol, setProtocol] = useState(null)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        contractRequestCRUD.search(
            {
                paging: { skip: 0 },
                filter: {
                    contractId: {
                        operand1: rowsPage?.number,
                        operator: "equals"
                    }
                }
            }
        ).then(({ result }) => {
            setContractRequest(result)
        })
    }, [])

    useEffect(() => {
     //   /api/v1/area/client/contract/result?contractId=
     getProtocol()
    }, [rowsPage?.number])

    const [clientNCA] = useNCALayerClient();

    useEffect(() => {
        if (!clientNCA)
            return;

        clientNCA.onmessage = function (msg) {
            console.log(msg)
            if (msg?.result === true) {
                dispatch(showSuccessSnackbar("Договор валидный", "success"))
            }
        };

    }, [clientNCA])

    const rowName = [
        { text: t("contractRequest.tableItemRequestObject.name") },
        { text: t("contractRequest.tableItemRequestObject.district") },
        { text: t("contractRequest.tableItemRequestObject.city") },
        { text: t("contractRequest.tableItemRequestObject.locality") },
        { text: t("contractRequest.tableItemRequestObject.address") },
        { text: t("contractRequest.tableItem.actions") },
    ]

    const rowName2 = [
        { text: t("specification.tableItem.service") },
        { text: t("specification.tableItem.unitOfMeasure") },
        { text: t("specification.tableItem.qty") },
        { text: t("specification.tableItem.price") },
        { text: t("specification.tableItem.anObject") },
        { text: t("specification.tableItem.servicesSchedule") },
        { text: t("specification.tableItem.actions") },
    ]

    const rowName3 = [
        { text: t("contract.tableItemSchedule.targetMonth") },
        { text: t("contract.tableItemSchedule.targetYear") },
        { text: t("contractRequest.tableItem.actions") },
    ]

    const [conform, setConform] = useState(true)

    const handleClose = () => {
        setOpen(false)
    }
    const handleCloseComment = () => {
        setOpenComment(false)
    }


    const isAdmin = checkRoles("SuperAdministrator", "Administrator");
    const isClient = checkRoles("Client");

    const { loading, start, stop } = useLoader(false);

    const { profile } = useSelector((state) => state.profile);

    useEffect(() => {
        (async function () {
            const personId = rowsPage?.client?.personId
            const legalPersonId = rowsPage?.client?.legalPersonId
            if (personId) {
                const data = await dispatch(getOnePerson(personId))
                setClient({ ...data, typeClient: 1 })
            } else if (legalPersonId) {
                const data = await dispatch(getOneLegalClient(legalPersonId))
                setClient({ ...data, typeClient: 2 })
            }
        }())
    }, [dispatch, rowsPage]);

    const [oneContract, setOneContract] = useState([])

    useEffect(() => {
        (async function () {
            const res = await dispatch(getOneContract(rowsPage?.number))
            setOneContract(res)
        }())
    }, [dispatch, rowsPage?.number])

    const handleCheckContract = async () => {
        if (lastElement) {
            start()
            const data = await contractSignerCRUD.get(lastElement.id)
            const jsonData = JSON.parse(data?.sign);
            await clientNCA.verifyXml(jsonData?.SignedXml)
            stop()
        }
    }

    const getProtocol = async () => {
        const result = await clientContractCRUD.getResult(rowsPage?.number);
        setProtocol(result)
    }

    const { push } = useHistory()

    const lastSigned = React.useMemo(() => {
        return oneContract?.signers?.find(el => el.lastSigned) || null
    }, [oneContract?.signers])

    const lastElement = React.useMemo(() => {
        return oneContract?.signers?.slice(-1)[0] || null
    }, [oneContract?.signers])

    const handleRecreationContract = async () => {
        await dispatch(changeContractState(rowsPage?.number, 7, 1))
        reload()
    }

    useEffect(() => {
        rowsPage.signers.forEach(el => {
            debugger
            if (isClient && el.user) {
                setConform(false)
            }
        })
    }, [profile?.userId, rowsPage.signers, rowsPage.status, reload, reloadValue])

    const handleTerminateContract = async () => {
        await dispatch(changeContractState(rowsPage?.number, 4, 8, comment))
        reload()
        await dispatch(showSuccessSnackbar("Договор расторгнут", "success"))
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    

    const { geo } = useSelector((state) => state.geo);
    const { filial, filialDivision } = useSelector((state) => state.filial);


    const openPopover = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const [comment, setComment] = useState('')
 
    debugger;

    return (
        <Box sx={{ padding: '0 20px' }}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab label="Договор" />
                {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && <Tab label="Согласующие" />}
                <Tab label="Результат"/>
            </Tabs>
            {value === 0 && <>
                {/* <GenerateDocument data={data}
                    filialDivision={data?.filialDivision}
                    active={true}
                    //localDistrict={localDistrict}
                    //                           contractObject={contractObject} educationServices={educationServices}
                    schedules={data?.schedules}
                    geo={geo} 
                    filial={data?.filial}
                    //localFilial={localFilial}
                  //  localFilialDivision={localFilialDivision} localCity={data.city}
                    legalClient={data?.client?.legalPerson} typeContract={data?.typeId}
                    //                           registeredAt={registeredAt}
                    //                           actionAt={actionAt} type={type} 
                    client={data?.person} 
                    //activeStep={activeStep}
                    //                           handleBack={handleBack} 
                    name={data?.name} number={data?.number}
                    specification={data.specifications}
                    //                           square={square}
                    //                           setSquare={setSquare}
                    //                           multiplicity={multiplicity}
                    //                           setMultiplicity={setMultiplicity}
                    //                           active={active} date1={date1} date2={date2} 
                    signers={data.signers}
                //                           handleFormGenerate={handleFormGenerate}
                /> */}

                <Grid
                    container
                    sx={{ marginTop: 1, marginBottom: 2 }}
                    spacing={3}
                >
                    <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                         <FileCard handleOpen={setOpen} data={data} /> 
                    </Grid>
                </Grid>

                {conform && <Button onClick={() => setOpenComment(true)}
                    sx={{ margin: '10px 0 10px 10px' }} color="success" variant="outlined"
                    startIcon={<CompareArrowsIcon />}>
                    Подписать
                </Button>}
                <Popover
                    id={idPopover}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <Stack direction="column" spacing={2} sx={{ padding: 3, width: '350px' }}>
                        <TextField
                            multiline
                            label={"Комментарий"}
                            onChange={e => setComment(e.target.value)}
                            fullWidth
                            value={comment}
                        />
                        <Button onClick={handleTerminateContract} fullWidth variant="contained" sx={{ mt: 3 }}>
                            {'Подтвердить'}
                        </Button>
                    </Stack>
                </Popover>
                {rowsPage.status === 'Отказано' && <LoadingButton loading={loading} onClick={handleRecreationContract}
                    sx={{ margin: '10px 0 10px 10px' }} color="warning"
                    variant="outlined">
                    Пересоздать
                </LoadingButton>}
            </>
            }

            {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && value === 1 &&
                <TableTabSigners reloadValue={reloadValue} reload={reload} number={rowsPage?.number}
                    status={rowsPage?.status} signers={rowsPage?.signers} />}
            <PopupContract lastSigned={lastSigned} rowsPage={rowsPage} typeId={rowsPage?.typeId} id={rowsPage?.number}
                open={open}
                handleClose={handleClose} />
            <PopupContractComment lastSigned={lastSigned} data={oneContract} setConfrom={setConform} reload={reload}
                id={rowsPage?.number}
                open={openComment} handleClose={handleCloseComment} />

            {
                value == 2 && <span>Результат еще не готов</span>
            }
        </Box>
    )
}

export default TableTabClient;
