import React from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import ClientLayout from "../../layouts/ClientLayout";
import {useParams} from "react-router-dom";
import {contractCRUD, clientContractCRUD} from "../../http/CRUD";
import useReload from "../../hooks/useReload";
import {Loader, TableTab} from "../../components";
import moment from "moment";
import useLoader from "../../hooks/useLoader";
import useRoles from "../../hooks/useRoles";
import {CONTRACT_ROUTE, CONTRACT_VIEW_ROUTE, DEAL_ADD_ROUTE, DEAL_ROUTE, GENERAL_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {Card} from "@mui/material";
import TableTabClient from '../../components/Contract/TableTabClient';

function ContractView(props) {
    const {id} = useParams()
    const [data, setData] = React.useState({})
    const {reload, reloadValue} = useReload();
    const {loading, start, stop} = useLoader(true);
    const {isClient} = useRoles();

    const fetchAsync = async () => {
        start()

        if (!isClient()) {
            const res = await contractCRUD.get(id);
            setData(res)            
        } else {
            const response = await clientContractCRUD.post({
                paging: {take: 1},
                filter: {
                    id: {
                        operand1: id,
                        operator: 'equals'
                    }
                }
            });
            const result = response.result;
            if (result.length > 0)
                setData(result[0]);
        }

        stop()
    }

    React.useEffect(() => {
        fetchAsync();
    }, [id, reloadValue])

    return (
        isClient() ? <ClientLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Договоры', link: GENERAL_ROUTE + '/contracts'},
                {text: 'Просмотр договора', link: CONTRACT_VIEW_ROUTE + `/${id}`},
            ]}/>
            {loading ? <Loader/> :
                <Card>
                     <TableTabClient data={data}
                     locale="contract" reloadValue={reloadValue} reload={reload} rowsPage={{
                    number: data.id,
                    registeredAt: moment(data.registeredAt).format('LL'),
                    actionAt: moment(data.deadline).format('LL'),
                    typeContract: data.type?.name,
                    filial: data.filial?.name,
                    payState: data.payState?.name,
                    summary: data.summary,
                    status: data.state?.name,
                    typeId: data.typeId,
                    client: data.client,
                    signers: data.signers}}/>
                </Card>}
            </ClientLayout>
            :
            <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Договоры', link: CONTRACT_ROUTE},
                {text: 'Просмотр договора', link: CONTRACT_VIEW_ROUTE + `/${id}`},
            ]}/>
            {loading ? <Loader/> :
                <Card>
                    <TableTab locale="contract" reloadValue={reloadValue} reload={reload} rowsPage={{
                    number: data.id,
                    registeredAt: moment(data.registeredAt).format('LL'),
                    actionAt: moment(data.deadline).format('LL'),
                    typeContract: data.type?.name,
                    filial: data.filial?.name,
                    payState: data.payState?.name,
                    summary: data.summary,
                    status: data.state?.name,
                    typeId: data.typeId,
                    client: data.client,
                    signers: data.signers
                }}/>
                </Card>}

        </DashboardLayout>
    );
}

export default ContractView;