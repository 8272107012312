import React, {useEffect, useState} from 'react';
import {style} from "../Popups/popup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {getContractFile, getContractFileS3} from "../../redux/actions/contract/contractActions";
import ContractView from "./ContractView";
import ContractViewPdf from "./ContractViewPdf";
import {postForDownloadFile} from "../../http";
import { checkRoles } from '../../functions/checkRoles';
import { clientContractCRUD, contractCRUD } from '../../http/CRUD';
import useRoles from '../../hooks/useRoles';

const PopupContract = ({open, handleClose, lastSigned, id, typeId, rowsPage}) => {
    const [initialValue, setInitialValue] = useState(null)
    const [error, setError] = useState('')
    const { isClient } = useRoles();


    const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_S3_API_URL : process.env.REACT_APP_PROD_S3_API_URL

    const dispatch = useDispatch()
    useEffect(() => {
        (async function () {
            try {
                debugger;
                let answer = [];
                if (!isClient()) {
                    answer = await dispatch(getContractFile(1, 10, {
                        filter: {
                            contractId: {
                                operand1: id,
                                operator: "equals"
                            },
                        }
                    }));
                }
                else {
                    const response = await clientContractCRUD.post({id}, `/file`);
                    answer = [response.value];
                }

                const {bucketName, key, objectName} = JSON.parse(answer[0]?.s3Path)
                if (typeId === 1 || typeId === 2) {
                    const answerS3 = await dispatch(getContractFileS3(bucketName, key, objectName))
                    setInitialValue(answerS3)
                }
                if (typeId === 3 || typeId === 4 || typeId === 5) {
                    const {data} = isClient ? await postForDownloadFile({bucketName, key, objectName}, `${url}/api/v1/area/client/contract/${id}/file`) :
                    await postForDownloadFile({bucketName, key, objectName}, `${url}api/v1/file/download`);
                    
                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    setInitialValue(fileURL);
                }
            } catch (e) {
                setError(true)
            }
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: '80%'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Просмотр договора
                    </Typography>
                    {error && <p>Не удалось загрузить договор (возможно, он не сформирован)</p>}
                    {initialValue && (typeId === 1 || typeId === 2) && <ContractView lastSigned={lastSigned} rowsPage={rowsPage} initialValue={initialValue}/>
                    }
                    {initialValue && (typeId === 3 || typeId === 4 || typeId === 5) && <ContractViewPdf initialValue={initialValue}/>
                    }
                </Box>
            </Modal>
        </div>
    );
}

export default PopupContract;