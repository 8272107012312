import { CRUD } from "./utils";

export const actCRUD = CRUD("act");
export const actFormCRUD = CRUD("actForm");
export const anObjectsCRUD = CRUD("anObject");
export const patientsCRUD = CRUD("patient");
export const probeTypeCRUD = CRUD("probeType");

export const infectionKindCRUD = CRUD("infectionKind");
export const diagnosisCRUD = CRUD("disease");
export const contractCRUD = CRUD("contract");
export const contractFileCRUD = CRUD("contractFile");
export const contractSignerCRUD = CRUD("contractSigner");
export const filialPackageCRUD = CRUD("filialServicePackage");
export const employeeCRUD = CRUD("employee");
export const employeesCRUD = CRUD("employee");
export const governmentCRUD = CRUD("governmentStandard");
export const brigadeTypeCRUD = CRUD("brigadeType");
export const resultFormCRUD = CRUD("resultForm");
export const userRoleCRUD = CRUD("userRole");
export const clientContractCRUD = CRUD("area/client/contract");
export const legalPersonCRUD = CRUD("legalPerson");
export const clientCRUD = CRUD("client");
export const personCRUD = CRUD("person");
export const dealCRUD = CRUD("deal");
export const dealCommercialCRUD = CRUD("dealCommercialOffer");
export const serviceScheduleCRUD = CRUD("serviceSchedule");

export const dictionaryCRUD = (entityType) => CRUD(entityType);

// Requests
export const contractRequestCRUD = CRUD(`contractRequest`);
export const serviceWithoutActRequestCRUD = CRUD("serviceWithoutActRequest");
export const withoutDepartureRequestCRUD = CRUD("withoutDepartureRequest");
export const departureScheduleRequestCRUD = CRUD("departureScheduleRequest");

export const educationServiceCRUD = CRUD("educationService");
export const educationServiceKindCRUD = CRUD("educationServiceKind");
export const geoObjectCRUD = CRUD("geoObject");
export const geoObjectTypeCRUD = CRUD("geoObjectType");
export const requestSectionCRUD = CRUD("requestSection");
export const requestStateCRUD = CRUD("requestState");
export const contractStateCRUD = CRUD(`contractState`);
export const resultCRUD = CRUD("result");
export const brigadeCRUD = CRUD("brigade");

export const requestTarificationCRUD = CRUD("requestTarification");
export const positionCRUD = CRUD("position");

export const filialCRUD = CRUD("filial");
export const filialDivisionCRUD = CRUD("filialDivision");
export const formMapCRUD = CRUD("formMap");
export const userCRUD = CRUD("user");
export const divisionCRUD = CRUD("division");
export const departmentCRUD = CRUD("department");

export const referalsCRUD = CRUD("referral");
export const referralCRUD = CRUD("referral");
export const desinfectionReferenceCRUD = CRUD("desinfectionReference");
export const anObjectReferralCRUD = CRUD("anObjectReferral");
export const referralSpecificationCRUD = CRUD("referralSpecification");
export const serviceCRUD = CRUD("service");
export const servicePriceListCRUD = CRUD("servicePriceList");
export const serviceToPackageCRUD = CRUD("serviceToPackage");
export const samplingPurposeCRUD = CRUD("samplingPurpose");
export const requestCRUD = CRUD("request");
export const surObjectCRUD = CRUD("surObject");

export const protocolsCRUD = CRUD("protocol");
export const protocolCRUD = CRUD("protocol");

