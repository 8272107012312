import {$authHost, $s3host} from "../../../http";
import {
    CONTRACT_ADD_ERROR,
    CONTRACT_ADD_REQUEST,
    CONTRACT_ADD_SUCCESS,
    CONTRACT_EDIT_ERROR,
    CONTRACT_EDIT_REQUEST,
    CONTRACT_EDIT_SUCCESS,
    CONTRACT_GET_ERROR,
    CONTRACT_GET_REQUEST,
    CONTRACT_GET_SUCCESS,
    CONTRACT_ONE_GET_ERROR,
    CONTRACT_ONE_GET_REQUEST,
    CONTRACT_ONE_GET_SUCCESS,
    CONTRACT_PAY_STATE_ERROR,
    CONTRACT_PAY_STATE_REQUEST,
    CONTRACT_PAY_STATE_SUCCESS,
    CONTRACT_STATE_GET_ERROR,
    CONTRACT_STATE_GET_REQUEST,
    CONTRACT_STATE_GET_SUCCESS,
    CONTRACT_TYPE_GET_ERROR,
    CONTRACT_TYPE_GET_REQUEST,
    CONTRACT_TYPE_GET_SUCCESS
} from "../../constants/contract/contractConstants";
import {getData, getOneData} from "../template/get";
import moment from "moment";
import {LOADING_FALSE, LOADING_TRUE} from "../../constants/genericConstants";
import {
    CONTRACT_FILE_GET_ERROR,
    CONTRACT_FILE_GET_REQUEST,
    CONTRACT_FILE_GET_SUCCESS
} from "../../constants/contract/contractFileConstants";
import {showSuccessSnackbar} from "../userActions";

moment.locale('en')

export const getContract = (page, size, args, nonGlobal) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const contract = await dispatch(getData(CONTRACT_GET_REQUEST, CONTRACT_GET_SUCCESS, CONTRACT_GET_ERROR, 'contract', true, page, null, null, true, size, args, nonGlobal))
    dispatch({type: LOADING_FALSE})
    return contract
};


export const getContractFile = (page, size, args) => async (dispatch) => {
    return await dispatch(getData(CONTRACT_FILE_GET_REQUEST, CONTRACT_FILE_GET_SUCCESS, CONTRACT_FILE_GET_ERROR, 'contractFile', true, page, null, null, false, size, args))
};

export const getContractFileS3 = (bucketName, key, objectName) => async (dispatch) => {
    const {data} = await $s3host.post("api/v1/file/download", {
        bucketName, key, objectName
    })
    return data
}

export const getClientContractFileS3 = (id) => async (dispatch) => {
    const {data} = await $s3host.get(`api/v1/area/client/contract/${id}/file`)
    return data
}

export const deleteContractFileS3 = (bucketName, key, objectName) => async (dispatch) => {
    const {data} = await $s3host.post("/api/v1/file/delete", {
        bucketName, key, objectName
    })
    return data
}

export const getOneContract = (id) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const oneContract = await dispatch(getOneData(id, CONTRACT_ONE_GET_REQUEST, CONTRACT_ONE_GET_SUCCESS, CONTRACT_ONE_GET_ERROR, 'contract'))
    dispatch({type: LOADING_FALSE})
    return oneContract
};


export const getTypeContract = () => async (dispatch) => {
    return await dispatch(getData(CONTRACT_TYPE_GET_REQUEST, CONTRACT_TYPE_GET_SUCCESS, CONTRACT_TYPE_GET_ERROR, 'contractType'))
};

export const getContractsState = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(CONTRACT_STATE_GET_REQUEST, CONTRACT_STATE_GET_SUCCESS, CONTRACT_STATE_GET_ERROR, 'contractState', true, page, null, null, true,  size, args, nonGlobal))
};

export const getContractStates = (args) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_STATE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/contractState/query",
            args || {},
            config,
        );
        dispatch({
            type: CONTRACT_STATE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: CONTRACT_STATE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const getContractsPayState = () => async (dispatch) => {
    return await dispatch(getData(CONTRACT_PAY_STATE_REQUEST, CONTRACT_PAY_STATE_SUCCESS, CONTRACT_PAY_STATE_ERROR, 'contractPayState'))
};

export const addContract = (name, number, registeredAt, actionAt, summary, clientId, typeId, specifications, signers, nameFilter, s3Path, filialId, filialDivisionId, cityId, educationServices,
                            schedules,
                            contractObject, isAdditionalAgreement, stateId, filials, dataOther) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADD_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {filter: {
                    [nameFilter]: {
                        operand1: clientId,
                        operator: "equals"
                    },
                }}
        )
        const {data} = await $authHost.post(
            "/api/v1/contract",
            {objects:  contractObject, filials, schedules: schedules ? schedules : [], educationServices, name, number, registeredAt: registeredAt, actionAt: actionAt, nceId: '', deadline: actionAt, summary, payStateId: 2, stateId: stateId ? stateId : 1, clientId: clientId ? dataClient?.result[0]?.id : null, typeId, specifications: specifications, signers: signers, filialId, filialDivisionId,
                isAdditionalAgreement: isAdditionalAgreement ? isAdditionalAgreement : false, ...dataOther}
        );
        await $authHost.post(
            "/api/v1/contractFile",
            {createdAt: new Date(), s3Path: {...s3Path}, contractId: data.id}
        );
        dispatch({
            type: CONTRACT_ADD_SUCCESS
        });
        dispatch(showSuccessSnackbar("Договор создан!", "success"))
        return data.id
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(showSuccessSnackbar("Ошибка при создании договора", "error"))
    }
};

export const editContract = (id, name, number, registeredAt, actionAt, summary, clientId, typeId, specifications, signers, nameFilter, s3Path, filialId, filialDivisionId, cityId, educationServices,
                             schedules,
                             contractObject, districtId, isAdditionalAgreement, stateId, filials, dataOther) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_EDIT_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {filter: {
                    [nameFilter]: {
                        operand1: clientId,
                        operator: "equals"
                    },
                }}
        )

        const answer = await dispatch(getContractFile(1, 10, {filter: {
                contractId: {
                    operand1: id,
                    operator: "equals"
                },
            }}))

        await $authHost.put(
            "/api/v1/contract",
            {id, filials, objects:  contractObject, schedules, educationServices, name, number, registeredAt: registeredAt, actionAt: actionAt, data: null
                , nceId: '', deadline: actionAt, payStateId: 2, summary, cityId, clientId: clientId ?  dataClient?.result[0]?.id : null, typeId, specifications: specifications, signers: signers, filialId, filialDivisionId, isAdditionalAgreement: isAdditionalAgreement ? isAdditionalAgreement : false, ...dataOther}
        );
        if (s3Path) {
            await $authHost.put(
                "/api/v1/contractFile",
                {id: answer[0]?.id, createdAt: new Date(), s3Path: {...s3Path}, contractId: id}
            );
        }
        dispatch({
            type: CONTRACT_EDIT_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(showSuccessSnackbar("Ошибка при редактировании договора", "error"))
    }
};

export const deleteContract = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADD_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/contract",
            {
                data: {
                    id
                }
            },
        );
        await dispatch(getContract())
        dispatch({
            type: CONTRACT_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const changeContractState = (id, oldStateId, newStateId, comment) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADD_REQUEST,
        });
        await $authHost.post(
            "/api/v1/contract/workflow/run",
            {id, oldStateId, newStateId, comment}
        )

        dispatch({
            type: CONTRACT_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addContractComment = (id, comment, xml, signedXml) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADD_REQUEST,
        })

        const request = { id, comment};

        if (xml && signedXml) {
            request.sign = {
                xml,
                signedXml
            }
        }

        await $authHost.post(
            "/api/v1/contract/workflow/sign/run",
            request
        )

        dispatch({
            type: CONTRACT_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
export const declineContract = (id, comment) => async (dispatch) => {
    try {

        const request = { id, comment};


        await $authHost.post(
            "/api/v1/contract/workflow/decline/run",
            request
        )

    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
