import React, {createContext, useCallback, useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {Box, CircularProgress, Paper, Step, StepLabel, Stepper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getTypeContract} from "../../redux/actions/contract/contractActions";
import moment from 'moment'
import {AddDate, AddSigners, AddSpecification} from "../index";
import {getUnitOfMeasure} from "../../redux/actions/genericActions";
import GenerateDocument from "./Pages/Add/GenerateDocument";
import {v4 as uuidv4} from 'uuid';
import 'moment/locale/ru'
import PopupWarning from "../../components/Popups/message/PopupWarning";
import {getEmployee} from "../../redux/actions/employee/employeeActions";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import LoadingDocument from "./Pages/Add/LoadingDocument";
import {CONTRACT_DETAILS_ROUTE, CONTRACT_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {getEducationServiceKind} from "../../redux/actions/educationService/educationServiceActions";
import {useHistory} from "react-router-dom";
import {anObjectsCRUD, serviceCRUD, serviceScheduleCRUD} from "../../http/CRUD";

moment.locale('ru')

export const ContractContext = createContext();

const ContractPage = () => {
    const [steps, setSteps] = useState(['Добавление данных', 'Добавление специфики', 'Добавление согласующих', 'Формирование договора']);
    const [qty, setQty] = useState(1)
    const history = useHistory()
    const [clientData, setClientData] = useState({})
    const [dealId, setDealId] = useState(null)

    React.useEffect(() => {
        if (history.location.state?.client) {
            const requestClient = history.location.state?.client;
            setLocalFilial(requestClient?.filialId)
            if (requestClient?.client?.legalPersonId) {
                setLegalClient(requestClient?.client?.legalPersonId)
                setClientData(requestClient?.client?.legalPerson)
            } else {
                setClient(requestClient?.client?.personId)
                setClientData(requestClient?.client?.person)
            }
            const clientRequestSpecifications = requestClient?.specifications?.map(el => {
                const newElement = {
                    Id: uuidv4(),
                    service: el.service,
                    serviceId: el.service?.id,
                    unitId: '',
                    unit: {},
                    quantity: el?.count,
                    price: el?.summary,
                    objectId: '',
                    schedule: el?.schedule,
                    scheduleId: el?.scheduleId,
                }
                return newElement
            })
            setSpecification(clientRequestSpecifications)
        }
    }, [history.location.state])

    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [service, setService] = useState(1);
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const {services, servicesCount, servicesPrice} = useSelector((state) => state.service);
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [specification, setSpecification] = useState([])
    const [signers, setSigners] = useState([])

    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [filials, setFilials] = useState([])

    const [localCity, setLocalCity] = useState(1)
    const [localDistrict, setLocalDistrict] = useState(1)
    const {geo} = useSelector((state) => state.geo);

    const [square, setSquare] = useState(0)
    const [multiplicity, setMultiplicity] = useState(0)


    const {filial, filialDivision} = useSelector((state) => state.filial);

    const [localFilial, setLocalFilial] = useState(null)
    const [localFilialDivision, setLocalFilialDivision] = useState(null)

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(true)
    const [typeContract, setTypeContract] = useState(false)

    const [data, setData] = useState({
        isDiscount: false,
        toDecrease: false,
        toIncrease: false,
        discountComment: ''
    })

    const handleChangeObject = useCallback(
        (key, value) => setData({...data, [key]: value}),
        [data]
    );

    const [number, setNumber] = useState(1)
    const [registeredAt, setRegisteredAt] = useState(new Date())
    const [actionAt, setActionAt] = useState(new Date())

    const [client, setClient] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const [contractObject, setContractObject] = useState([])

    const [type, setType] = useState(2)

    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const {contractType} = useSelector((state) => state.contract);
    const {client: clientRedux} = useSelector((state) => state.client);
    const {
        legalClient: legalClientRedux,
        legalClientCount: legalClientReduxCount
    } = useSelector((state) => state.legalClient);
    const {persons, personsCount} = useSelector((state) => state.person);
    const {employee, employeeCount} = useSelector((state) => state.employee);

    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);

    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const [rowsModal, setRowsModal] = useState({})
    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()
    const [district, setDistrict] = useState([])

    const setTypeHandle = (val) => {
        setType(val)
    }

    useEffect(() => {
        if (type === 3 || type === 4) {
            setSteps(['Добавление данных', 'Добавление специфики', 'Загрузка договора'])
        } else if (type === 5) {
            setSteps(['Добавление данных', 'Загрузка договора'])
        } else {
            setSteps(['Добавление данных', 'Добавление специфики', 'Добавление согласующих', 'Формирование договора'])
        }
    }, [type])

    const handleForm = async (e) => {
        e.preventDefault()
        handleNext()
    }

    const handleFormGenerate = async (e) => {
        e.preventDefault()
        setDate1(moment(registeredAt).format('LL'))
        setDate2(moment(actionAt).format('LL'))
        const checkboxes = document.querySelectorAll( 'input[type="checkbox"]' );

        checkboxes.forEach( el => {
            el.addEventListener( 'click', () => {
                console.log( 'toggle' );
                el.parentNode.classList.toggle( 'checked' );
            } );
        } )
        setActive(true)
    }

    const handleOpenEditPopup = async (row) => {
        await setRowsModal(row)
        setOpenModal(true)

    }

    const emptyStateSpec = () => {
        setQty(1)
    }

    const handleFormSpec = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            service: {},
            serviceId: '',
            unitId: '',
            unit: {},
            quantity: qty,
            square: 1,
            multiplicity: 1,
            price: 0,
            objectId: '',
            scheduleId: '',
        }
        setSpecification(oldArray => [...oldArray, newElement])
        emptyStateSpec()
    }

    const handleAddTest = async () => {
        const service = await serviceCRUD.get(10872)
        const schedule = await serviceScheduleCRUD.get(12)
        const anObject = await anObjectsCRUD.get(151219)
        const object = {
            Id: uuidv4(),
            objectId: 151219,
            object: anObject,
            price: 1265,
            quantity: 1,
            scheduleId: 12,
            serviceId: 10872,
            schedule: schedule,
            service: service,
            square: 1,
            multiplicity: 1,
            unitId: 5970
        }
        setSpecification(oldArray => [...oldArray, object])
    }

    const handleSigners = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            employeeId: '',
            isSigned: false
        }
        setSigners(oldArray => [...oldArray, newElement])
    }
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    const handleSignersSeby = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            employeeId: authorizedEmployee.id,
            employee: authorizedEmployee,
            isSigned: false
        }
        setSigners(oldArray => [...oldArray, newElement])
    }

    const handleContractObjectRequest = () => {
        const newElement = {
            Id: uuidv4(),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: '',
            objectId: ''
        }
        setContractObject(oldArray => [...oldArray, newElement])
    }

    useEffect(() => {
        setLoading(true)
        let resClient
        let resType
        let resUnit
        let resCity
        let resFilial
        let resFilialDivision
        const getLocal = async () => {
            resType = await dispatch(getTypeContract())
            resUnit = await dispatch(getUnitOfMeasure(1, null, 30))
            setDistrict(await dispatch(getGeoObject(1, 10000, {
                filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }
            }, true)))
            await dispatch(getEducationServiceKind())
            await dispatch(getEmployee())
            resCity = await dispatch(getGeoObject(1, 10000, {
                filter: {
                    typeId: {
                        operand1: 3,
                        operator: "equals"
                    },
                }
            }))
            resFilial = await dispatch(getFilial(1, 1000))
            resFilialDivision = await dispatch(getFilialDivision(1, 10000))

            if (resUnit && resClient && resType && resCity && resFilial && resFilialDivision) {
                setLocalCity(resCity[0].id)
                setType(resType[0].id)
                setUnitOfMeasure(resUnit[0].id)
                setLocalFilial(resFilial[0].id)
            }
            setLoading(false)
        }
        getLocal()
    }, [dispatch])

    const [educationServices, setEducationServices] = useState([])
    const [localEducationServiceKind] = useState('')
    const {educationServiceKind} = useSelector((state) => state.education);

    const handleEducationServices = () => {
        const educationServiceKindIdx = educationServiceKind.findIndex(el => el.id === localEducationServiceKind)
        const newElement = {
            Id: uuidv4(),
            kind: educationServiceKind[educationServiceKindIdx]?.name,
            kindId: localEducationServiceKind,
            service: '',
            serviceId: '',
        }
        setEducationServices(oldArray => [...oldArray, newElement])
    }

    const [schedules, setSchedules] = useState([])
    const [localTargetMonth, setLocalTargetMonth] = useState(new Date())
    const [localTargetYear, setLocalTargetYear] = useState(new Date())

    const handleSchedules = () => {
        const newElement = {
            Id: uuidv4(),
            targetMonth: localTargetMonth,
            targetYear: localTargetYear,
        }
        setSchedules(oldArray => [...oldArray, newElement])
    }


    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Договоры', link: CONTRACT_ROUTE},
                {text: 'Добавление договора', link: CONTRACT_DETAILS_ROUTE},
            ]}/>
            <Paper sx={{pb: 5}}>
                {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> : <Box sx={{pt: 5}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === 0 &&
                        <ContractContext.Provider value={{
                            filialDivision,
                            filial,
                            dealId,
                            setDealId,
                            filials,
                            setFilials,
                            handleSchedules,
                            localDistrict,
                            setLocalDistrict,
                            localTargetYear,
                            setLocalTargetYear,
                            localTargetMonth,
                            setLocalTargetMonth,
                            setSchedules,
                            schedules,
                            setEducationServices,
                            educationServiceKind,
                            localEducationServiceKind,
                            educationServices,
                            handleEducationServices,
                            setSpecification,
                            anObjectsCount,
                            anObjects,
                            district,
                            handleContractObjectRequest,
                            contractObject,
                            setContractObject,
                            data,
                            handleChangeObject
                        }}>
                            <AddDate clientData={clientData} filial={filial} filialDivision={filialDivision} setLocalFilial={setLocalFilial}
                                     setLocalFilialDivision={setLocalFilialDivision} localFilial={localFilial}
                                     localFilialDivision={localFilialDivision} geo={geo} localCity={localCity}
                                     setLocalCity={setLocalCity} personRedux={persons} personCount={personsCount}
                                     legalClientRedux={legalClientRedux}
                                     legalClientReduxCount={legalClientReduxCount} legalClient={legalClient}
                                     setLegalClient={setLegalClient} typeContract={typeContract}
                                     setTypeContract={setTypeContract} activeStep={activeStep} handleBack={handleBack}
                                     steps={steps} number={number}
                                     setNumber={setNumber} client={client} type={type} name={name}
                                     setName={setName} actionAt={actionAt} clientRedux={clientRedux}
                                     contractType={contractType}
                                     setActionAt={setActionAt} handleForm={handleForm}
                                     registeredAt={registeredAt} setRegisteredAt={setRegisteredAt} setClient={setClient}

                                     setType={setTypeHandle}/>
                        </ContractContext.Provider>}
                    {activeStep === 1 && (type !== 5) &&
                        <ContractContext.Provider value={{
                            square, setSquare,
                            multiplicity, setMultiplicity,
                        }}>
                            <AddSpecification
                                number={number}
                                handleAddTest={handleAddTest}
                                name={name}
                                registeredAt={registeredAt}
                                actionAt={actionAt}
                                legalClient={legalClient}
                                type={type}
                                client={client}
                                localFilial={localFilial}
                                localCity={localCity}
                                typeContract={typeContract}
                                localFilialDivision={localFilialDivision}
                                educationServices={educationServices}
                                schedules={schedules}
                                contractObject={contractObject}
                                servicesCount={servicesCount} handleDeleteSpec={handleDeleteSpec} anObjects={anObjects}
                                anObjectsLocal={anObjectsLocal} setAnObjectsLocal={setAnObjectsLocal}
                                anObjectsCount={anObjectsCount} servicesScheduleLocal={servicesScheduleLocal}
                                servicesSchedule={servicesSchedule} servicesScheduleCount={servicesScheduleCount}
                                setServicesScheduleLocal={setServicesScheduleLocal} setOpen={handleOpenEditPopup}
                                handleNext={handleNext}
                                setSpecification={setSpecification} specification={specification}
                                handleFormSpec={handleFormSpec} steps={steps} handleBack={handleBack}
                                activeStep={activeStep} service={service}
                                setService={setService} serviceKind={serviceKind}
                                localServiceKind={localServiceKind}
                                services={services.map(el => ({id: el.id, name: el.name, price: el.price}))}
                                servicesPrice={servicesPrice}
                                qty={qty} setQty={setQty} setLocalServiceKind={setLocalServiceKind}
                                setUnitOfMeasure={setUnitOfMeasure}
                                unitOfMeasure={unitOfMeasure} unityOfMeasure={unityOfMeasure}
                            />
                        </ContractContext.Provider>
                    }
                    {activeStep === 1 && (type === 5) &&
                        <LoadingDocument filials={filials} contractObject={contractObject}
                                         educationServices={educationServices} schedules={schedules} geo={geo}
                                         filial={filial} localFilial={localFilial}
                                         localFilialDivision={localFilialDivision} localCity={localCity}
                                         legalClient={legalClient} typeContract={typeContract}
                                         registeredAt={registeredAt}
                                         actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                         handleBack={handleBack} name={name} number={number}
                                         specification={specification}
                                         active={active} date1={date1} date2={date2} signers={signers}/>}
                    {activeStep === 2 && (type === 1 || type === 2) &&
                        <AddSigners handleSignersSeby={handleSignersSeby} activeStep={activeStep} handleBack={handleBack} steps={steps}
                                    handleNext={handleNext} signers={signers} setSigners={setSigners}
                                    employee={employee} employeeCount={employeeCount} handleSigners={handleSigners}
                        />}
                    {activeStep === 2 && (type === 3 || type === 4) &&
                        <LoadingDocument filials={filials} localDistrict={localDistrict} contractObject={contractObject}
                                         educationServices={educationServices} schedules={schedules} geo={geo}
                                         filial={filial} localFilial={localFilial}
                                         localFilialDivision={localFilialDivision} localCity={localCity}
                                         legalClient={legalClient} typeContract={typeContract}
                                         registeredAt={registeredAt}
                                         actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                         handleBack={handleBack} name={name} number={number}
                                         specification={specification}
                                         active={active} date1={date1} date2={date2} signers={signers}/>}
                    {activeStep === 3 &&
                        <GenerateDocument data={data} filialDivision={filialDivision} localDistrict={localDistrict}
                                          contractObject={contractObject} educationServices={educationServices}
                                          schedules={schedules} geo={geo} filial={filial} localFilial={localFilial}
                                          localFilialDivision={localFilialDivision} localCity={localCity}
                                          legalClient={legalClient} typeContract={typeContract}
                                          registeredAt={registeredAt}
                                          actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                          handleBack={handleBack} name={name} number={number}
                                          specification={specification}
                                          square={square}
                                          setSquare={setSquare}
                                          multiplicity={multiplicity}
                                          setMultiplicity={setMultiplicity}
                                          active={active} date1={date1} date2={date2} signers={signers}
                                          handleFormGenerate={handleFormGenerate}/>}
                </Box>}
            </Paper>
            <PopupWarning/>
        </DashboardLayout>
    );
};

export default ContractPage;
