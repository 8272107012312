export const initialValuePerson = ({
                                       specification,
                                       proxy,
                                       city ='Не задано',
                                       supervisorName = 'Не задано',
                                       position,
                                       clientData: clientDataServer,
                                       number = 'Не задано',
                                       filial,
                                       filialDate,
                                       client = 'Не задано',
                                       inn = 'Не задано',
                                       bin = 'Не задано',
                                       date2,
                                       date1,
                                       price,
                                       filialKz,
                                       phoneNumber = 'Не задано',
                                       addressLine = 'Не задано',
                                       supervisor = 'Не задано',
                                       contractObject
                                   }) => {
    let clientData = {...clientDataServer}
    Object.keys(clientDataServer).map(el => {
        if (!clientData[el]) {
            clientData[el] = 'Не задано'
        }
    })
    
   //  let filialDate = {...filialDateServer}
   //  Object.keys(filialDateServer).map(el => {
   //      if (!filialDate[el]) {
   //          filialDate[el] = 'Не задано'
   //      }
   //  })
    let sum = 0;
    specification.map(el => {
      sum += ((el.quantity ? el.quantity : 1) * el.price)
   })
   debugger
    return `
    <figure class="table">
   <table>
      <tbody>
         <tr>
            <td>
               <p>&nbsp;</p>
               <p>№ ${number}&nbsp;</p>
               <p>ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫ</p>
               <p style="text-align:center;">&nbsp;</p>
               <p><strong>(керегін жазу) қ.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ${date1}</strong></p>
               <p>&nbsp;</p>
               <p>Бұдан әрі «Орындаушы» деп аталатын, <strong>ҚР ДСМ СЭБК</strong> <strong>«Ұлттық сараптама орталығы» ШЖҚ РМК</strong>, __ 202__ ж. __ Бас сенімхат негізінде әрекет ететін <strong>ҚР ДСМ СЭБК</strong> <strong>«Ұлттық сараптама орталығы» ШЖҚ РМК</strong> филиал директоры <strong>${supervisor}&nbsp;</strong>бір тараптан және және <strong>${client}</strong>, бұдан әрі «Тапсырыс беруші» деп аталатын, басқа жағынан, бірлесіп ескергенде «Тараптар» , жеке айтқанда «Тарап»&nbsp; &nbsp;немесе&nbsp; жоғарыда айтылғандай, осы Шартты (бұдан әрі - шарт) жасасты:</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p style="text-align:center;"><strong>2. Шарттың құны және төлем шарттары</strong></p>
               <p>2.1 Шарттың жалпы сомасы Қосымшада көрсетілген және қызметтерді жүзеге асырумен байланысты барлық шығыстарды, сондай-ақ Қазақстан Республикасының заңнамасында көзделген барлық салықтар мен алымдарды қамтиды.</p>
               <p>2.2 Тапсырыс беруші Шарт күшіне енген күннен бастап 3 (үш) жұмыс күн ішінде Орындаушының есеп айырысу шотына/ кассасына&nbsp; 100% көлемде аванстық төлем жасайды.</p>
               <p>2.3 Саны және құны бойынша ұсынылатын Қызметтер сомасы осы Шарттың 1-қосымшасында көрсетілген.</p>
               <p><i>Ескерту: Келесі нұсқаның бірін таңдаңыз:</i></p>
               <div class="todo-list-wrapper">
               <ul class="todo-list">
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled [checked] />
                            <span class="todo-list__label__description">□&nbsp;Қызметтерді көрсету үлгілері Тапсырыс берушімен дербес қамтамасыз етіледі.&nbsp; &nbsp;</span>
                        </label>
                    </li>
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled />
                            <span class="todo-list__label__description">□ Қызмет көрсету үшін мамандардың жол жүруі Тапсырыс берушінің көлік құралдарымен жүзеге асырылады.</span>
                        </label>
                    </li>
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled />
                            <span class="todo-list__label__description">□ Қызметтерді көрсету үшін мамандардың жол жүруі Орындаушының бағаларына сәйкес Орындаушының автокөлігімен жүзеге асырылады.</span>
                        </label>
                    </li>
                </ul>
                </div>
               <p>&nbsp;</p>
               <p><i><strong>Тут должна ставиться галочка на одном из трех пунктов</strong></i></p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p style="text-align:center;"><strong>7. Тараптардың реквизиттері</strong></p>
               <p><strong>Орындаушы</strong></p>
               <p>${filial}</p>
               <p>Мекенжайы: ${filialDate?.addressLine}</p>
               <p>БСН ${filialDate?.bin}&nbsp;</p>
               <p>ЖСК KZ ${filialDate?.bankAccountNumber}&nbsp;</p>
               <p>&nbsp;</p>
               <p>БСК ${filialDate?.bankBik}</p>
               <p>КБе ${filialDate?.beneficiaryCode}</p>
               <p>Тел ${filialDate?.phoneNumber}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>Директор&nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${supervisor} &nbsp;Т.А.Ә.</p>
               <p>&nbsp;М.О.</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p> 
               <p><strong>Тапсырыс беруші &nbsp;</strong></p>
               <p>${clientData?.fullName}</p>
               <p>Мекенжайы: ${addressLine}</p>
               <p>БСН ${inn}/${bin}&nbsp;</p>
               <p>ЖСК KZ ${clientData?.bankAccountNumber}&nbsp;</p>
               <p>Банк ${clientData?.bankName}</p>
               <p>БСК ${clientData?.bankBik}</p>
               <p>КБе ${clientData?.beneficiaryCode}</p>
               <p>Тел ${clientData?.phoneNumber || '___________________'} &nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp; &nbsp;</p>
               <p>${clientData?.fullName} &nbsp;Т.А.Ә.</p>
               <p>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Қолы&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; М.О.</p>
               <p>&nbsp;</p>
            </td>
            <td>
               <p style="text-align:center;">&nbsp;</p>
               <p style="text-align:center;"><strong>ДОГОВОР ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ&nbsp;</strong></p>
               <p style="text-align:center;"><strong>№ ${number}</strong></p>
               <p>&nbsp;</p>
               <p><strong> ${city}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ${date1}</strong></p>
               <p>&nbsp;</p>
               <p><strong>РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК,&nbsp;</strong>именуемое в дальнейшем «Исполнитель», в лице <strong>${position} филиала ${filial} ${supervisor},</strong> действующего на основании ${proxy?.proxy} №${proxy?.proxyNumber} от ${proxy?.proxyDate} с одной стороны, и <strong>${client}</strong>, именуемое в дальнейшем «Заказчик», с другой стороны, при совместном упоминании именуемые&nbsp;«Стороны», а по отдельности&nbsp;«Сторона» или как указано выше, заключили настоящий договор (далее - Договор) о нижеследующем:</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p style="text-align:center;"><strong>2. Стоимость Договора и условия оплаты</strong></p>
               <p>2.1 Общая сумма Договора указана в Приложении к настоящему Договору и включает все расходы, связанные с выполнением услуг, а также все налоги и сборы, предусмотренные законодательством Республики Казахстан.&nbsp;</p>
               <p>2.2 Заказчик в течение 3 (трех) рабочих дней со дня вступления Договора в силу, производит на расчетный счет/кассу Исполнителя авансовый платеж в размере 100%.</p>
               <p>2.3 Объем оказываемых Услуг в количественном и стоимостном выражении оговорен в Приложении к Договору.</p>
               <p><i>Примечание: Выберите один из следующих вариантов:</i></p>
               <div class="todo-list-wrapper">
               <ul class="todo-list">
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled [checked] />
                            <span class="todo-list__label__description">Пробы для оказания Услуг предоставляются Заказчиком самостоятельно</span>
                        </label>
                    </li>
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled />
                            <span class="todo-list__label__description">Выезд специалистов для оказания услуг осуществляется на автотранспорте Заказчика</span>
                        </label>
                    </li>
                    <li>
                        <label class="todo-list__label">
                            <input type="checkbox" disabled />
                            <span class="todo-list__label__description">Выезд специалистов для оказания услуг осуществляется на автотранспорте Исполнителя, согласно Прейскуранту цен Исполнителя</span>
                        </label>
                    </li>
                </ul>
                </div>
               <p>&nbsp;</p>
               <p><i><strong>Тут должна ставиться галочка на одном из трех пунктов</strong></i></p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p style="text-align:center;"><strong>7. Реквизиты сторон</strong></p>
               <p><strong>Исполнитель</strong></p>
               <p>${filial}</p>
               <p>Адрес: ${filialDate?.addressLine}</p>
               <p>БИН ${filialDate?.bin}</p>
               <p>ИИК KZ ${filialDate?.bankAccountNumber}&nbsp;</p>
               <p>&nbsp;</p>
               <p>БИК ${filialDate?.bankBik}</p>
               <p>КБе ${filialDate?.beneficiaryCode}</p>
               <p>Тел ${filialDate?.phoneNumber}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>Директор&nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${supervisor} Ф.И.О.</p>
               <p>М.П.</p>
               <p style="text-align:center;">&nbsp;</p>
               <p style="text-align:center;">&nbsp;</p>
               <p style="text-align:center;">&nbsp;</p>
               <p><strong>Заказчик&nbsp;</strong></p>
               <p>${clientData?.fullName}</p>
               <p>Адрес: ${addressLine}</p>
               <p>ИИН/БИН ${inn}/${bin}&nbsp;</p>
               <p>ИИК KZ ${clientData?.bankAccountNumber}&nbsp;</p>
               <p>Банк ${clientData?.bankName}</p>
               <p>БИК ${clientData?.bankBik}</p>
               <p>КБе ${clientData?.beneficiaryCode}</p>
               <p>Тел ${clientData?.phoneNumber || 'Не задано'}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${clientData?.fullName} Ф.И.О.</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Подпись</p>
               <p style="text-align:center;">&nbsp; &nbsp; &nbsp; &nbsp;М.П.</p>
            </td>
         </tr>
      </tbody>
   </table>
</figure>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align:right;"><i>Приложение</i></p>
<p style="text-align:right;"><i>к Договору</i></p>
<p style="text-align:right;"><i>№${number}&nbsp;</i></p>
<p style="text-align:right;"><i>от ${date1}</i></p>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:center;"><strong>Перечень закупаемых услуг</strong></p>
<p style="text-align:center;">&nbsp;</p>
<figure class="table">
   <table>
      <tbody>
         <tr>
            <td>
               <p style="text-align:center;"><strong>№</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Вид услуги</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Наименование услуги/анализа</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Единица измерения</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>График</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Количество&nbsp;</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Цена</strong></p>
            </td>
            <td>
               <p style="text-align:center;"><strong>Сумма</strong></p>
            </td>
         </tr>
         ${specification && specification.map((el, idx) => {
        return `<tr>
        <td>
            ${idx + 1}
        </td>
        <td>
            ${el.service?.category?.name || 'Не задано'}
        </td>
        <td>
            ${el.service?.name || el.group?.name || 'Не задано'}
        </td>
        <td>
            ${el.unit?.name ? el.unit?.name : el.unit || 'Не задано'}
        </td>
        <td>
            ${el?.schedule?.name || ''}
        </td>
        <td>
            ${el.quantity || 'Не задано'}
        </td>
        <td>
            ${el.price || 'Не задано'}
        </td>
        <td>
            ${((el.quantity ? el.quantity : 1) * el.price).toString()}
        </td>
    </tr>`
    })}
      </tbody>
   </table>
</figure>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">ИТОГО: ${sum}</p>
<p style="text-align:center;"><strong>ГРАФИК ИССЛЕДОВАНИЙ</strong></p>
<figure class="table">
   <table>
      <tbody>
         <tr>
            <td>п№</td>
            <td>Месяц</td>
            <td>Год</td>
         </tr>
         <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
         </tr>
      </tbody>
   </table>
</figure>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">&nbsp;</p>
<figure class="table">
   <table>
      <tbody>
         <tr>
            <td>
               <p style="text-align:center;"><strong>8. Тараптардың реквизиттері</strong></p>
               <p>&nbsp;</p>
               <p><strong>Орындаушы</strong></p>
               <p>${filial}</p>
               <p>Мекенжайы: ${filialDate?.addressLine}</p>
               <p>БСН ${filialDate?.bin}&nbsp;</p>
               <p>ЖСК KZ${filialDate?.bankAccountNumber}&nbsp;</p>
               <p>&nbsp;</p>
               <p>БСК ${filialDate?.bankBik}</p>
               <p>КБе ${filialDate?.beneficiaryCode}</p>
               <p>Тел ${filialDate?.phoneNumber}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p>Директор&nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${supervisor} &nbsp;Т.А.Ә.</p>
               <p>&nbsp;М.О.</p>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
               <p><strong>Тапсырыс беруші &nbsp;</strong></p> 
               <p>${clientData?.fullName}</p>
               <p>Мекенжайы: </p>
               <p>БСН ${inn}/${bin}&nbsp;</p>
               <p>ЖСК KZ${clientData?.bankAccountNumber || 'Не задано'}&nbsp;</p>
               <p>Банк ${clientData?.bankName}</p>
               <p>БСК ${clientData?.bankBik || 'Не задано'}</p>
               <p>КБе ${clientData?.beneficiaryCode}</p>
               <p>Тел ${clientData?.phoneNumber || 'Не задано'}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${clientData?.fullName} &nbsp;Т.А.Ә.</p>
               <p>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Қолы&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; М.О.</p>
               <p>&nbsp;</p>
            </td>
            <td>
               <p style="text-align:center;"><strong>8. Реквизиты сторон</strong></p>
               <p>&nbsp;</p>
               <p><strong>Исполнитель</strong></p>
               <p>${filial}</p>
               <p>Адрес: ${filialDate?.addressLine}</p>
               <p>БИН ${filialDate?.bin}&nbsp;</p>
               <p>ИИК KZ ${filialDate?.bankAccountNumber}&nbsp;</p>
               <p>&nbsp;</p>
               <p>БИК ${filialDate?.bankBik}</p>
               <p>КБе ${filialDate?.beneficiaryCode}</p>
               <p>Тел ${filialDate?.phoneNumber}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>Директор&nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${supervisor} Ф.И.О.</p>
               <p style="text-align:center;">М.П.</p>
               <p style="text-align:center;">&nbsp;</p>
               <p style="text-align:center;">&nbsp;</p>
               <p><strong>Заказчик&nbsp;</strong></p>
               <p>${clientData?.fullName}</p>
               <p>Адрес: ${addressLine}</p>
               <p>ИИН/БИН ${inn}/${bin}&nbsp;</p>
               <p>ИИК KZ ${clientData?.bankAccountNumber}&nbsp;</p>
               <p>Банк ${clientData?.bankName}</p>
               <p>БИК ${clientData?.bankBik}</p>
               <p>КБе ${clientData?.beneficiaryCode}</p>
               <p>Тел ${clientData?.phoneNumber || 'Не задано'}&nbsp;</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
               <p>&nbsp;</p>
               <p>${clientData?.fullName} Ф.И.О.</p>
               <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Подпись</p>
               <p>&nbsp;</p>
            </td>
         </tr>
      </tbody>
   </table>
</figure>
   `
}
