import React, {useContext} from 'react'
import {Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Stack, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
    DatePickerShared,
    SelectShared
} from "../../../../components";
import Typography from "@mui/material/Typography";
import {ContractRequestObject, EducationServicesTableContractRequest} from "../../../index";
import {ContractContext} from "../../ContractPage";
import TextShared from "../../../../components/Shared/TextShared";
import useCreateObjectForm from '../../../../components/Crud/Models/useCreateObjectForm';
import LegalClientField from "../../../../components/Fields/LegalClientField";
import PersonField from "../../../../components/Fields/PersonField";
import ContractFilials from "../../../../components/Contract/ContractFilials";
import ContractField from "../../../../components/Fields/ContractField";
import {checkRoles} from "../../../../functions/checkRoles";
import DealField from "../../../../components/Fields/DealField";
import {dealCRUD} from "../../../../http/CRUD";
import {removeEmpty} from "../../../../functions";
import {v4 as uuidv4} from "uuid";

const AddDate = ({
                     typeContract,
                     setLegalClient,
                     legalClient,
                     setTypeContract, setClient, setType,
                     steps,
                     clientData,
                     handleBack,
                     activeStep,
                     handleForm,
                     name,
                     setName,
                     registeredAt,
                     setRegisteredAt,
                     actionAt,
                     setActionAt,
                     client,
                     type,
                     contractType,
                     filial,
                     filialDivision,
                     localFilialDivision,
                     localFilial,
                     setLocalFilialDivision,
                     setLocalFilial,
                     number,
                     setNumber
                 }) => {
    const {
        isAdditional,
        contractId,
        setContractId,
        contractObject,
        setContractObject,
        district,
        handleContractObjectRequest,
        setEducationServices,
        educationServiceKind,
        localEducationServiceKind,
        educationServices,
        handleEducationServices,
        filials,
        dealId,
        setDealId,
        data,
        handleChangeObject,
        setSpecification,
    } = useContext(ContractContext)
    const {openCreateObjectForm, DIALOG} = useCreateObjectForm();

    const disabledLegal = React.useMemo(() => {
        if (type == 5) {
            return !filials || filials.length == 0;
        }

        return (!legalClient && !client) || !contractObject?.length;

    }, [client, legalClient, filials, contractObject, typeContract])

    const disabledPerson = React.useMemo(() => {
        if (type == 5) {
            return !filials || filials.length == 0;
        }

        return (!legalClient && !client);
    }, [client, legalClient, filials, contractObject, typeContract])


    const isAdmin = checkRoles("SuperAdministrator")
    
    return (
        <Box component="form" onSubmit={handleForm}
             sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
            <DIALOG/>
            <Grid container spacing={2}>
                {isAdditional && <Grid item xs={12}>
                    <ContractField
                        fullWidth
                        value={contractId}
                        onChange={(contract) => {
                            setContractId(contract.id)
                            if (contract?.client?.legalPersonId) {
                                setLegalClient(contract?.client?.legalPersonId)
                                setTypeContract(true)
                            } else {
                                setClient(contract?.client?.personId)
                                setTypeContract(false)
                            }
                        }}
                    />
                </Grid>}
                {!isAdditional && <Grid item xs={12}>
                    <DealField
                        fullWidth
                        value={dealId}
                        onChange={async (deal) => {
                            setDealId(deal.id)
                            const dealFromServer = await dealCRUD.get(deal.id);

                            const specifications = dealFromServer?.specifications?.map(el => {
                                const oldSerivce = {...el.service}
                                const newService = {...el.service}
                                let service = removeEmpty(newService)
                                delete service['id'];
                                delete service['categoryId'];
                                delete service['code'];
                                delete service['costCoefficient'];
                                delete service['createdAt'];
                                delete service['departmentId'];
                                delete service['groupId'];
                                delete service['group'];
                                delete service['kindId'];
                                delete service['kind'];
                                delete service['prices'];
                                delete service['name'];
                                delete service['subGroup'];
                                delete service['subGroupId'];
                                delete service['typeId'];

                                return {
                                    ...service,
                                    Id: uuidv4(),
                                    objectId: '',
                                    scheduleId: '',
                                    schedule: {},
                                    object: {},
                                    serviceId: oldSerivce?.id,
                                    qty: 1,
                                    quantity: 1,
                                    service: oldSerivce,
                                    unit: service?.unit?.name,
                                    price: el?.service?.price ? el?.service?.price : el?.service?.prices?.length ? el?.service?.prices[0]?.price : 0,
                                    square: 1,
                                    multiplicity: 1,
                                }
                            })
                            const objects = dealFromServer?.objects?.map(el => {
                                return {
                                    Id: uuidv4(),
                                    addressLine: el?.addressLine,
                                    cityId: el?.cityId,
                                    districtId: el?.districtId,
                                    localityId: el?.localityId || "",
                                    name: "",
                                    objectId: el?.objectId,
                                    object: el?.object
                                }
                            })
                            if (dealFromServer?.contact?.legalPersonId) {
                                setLegalClient(dealFromServer?.contact?.legalPersonId)
                                setName(dealFromServer?.contact?.legalPerson?.name)
                            }
                            if (dealFromServer?.contact?.personId) {
                                setClient(dealFromServer?.contact?.personId)
                            }

                            setContractObject(objects)

                            setSpecification(specifications)
                        }}
                    />
                </Grid>}
                <Grid item xs={6}>
                    <TextShared value={number} setValue={setNumber} label="Номер"/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        id="name"
                        name="name"
                        label="Название"
                    />
                </Grid>
                {type !== 5 && <><Grid item xs={6}>
                    <SelectShared style={{maxWidth: '100%'}} value={localFilial} setValue={setLocalFilial} label="Филиал" array={filial}/>
                </Grid>
                    <Grid item xs={6}>
                        <SelectShared style={{maxWidth: '100%'}} disabled={!localFilial} value={localFilialDivision} required={false}
                                      setValue={setLocalFilialDivision}
                                      label="Районное отделение"
                                      array={filialDivision && filialDivision.filter(el => el.filialId === localFilial)}/>
                    </Grid></>}
                <Grid item xs={6}>
                    <DatePickerShared setValue={setRegisteredAt} value={registeredAt} label="Дата регистрации"
                                      fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared setValue={setActionAt} value={actionAt} label="Дата действия" fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography>Тип договора</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>с юр. лицом</Typography>
                            <Switch onChange={(event) => setTypeContract(event.target.checked)} checked={typeContract}
                                    inputProps={{'aria-label': 'ant design'}}/>
                            <Typography>с физ. лицом</Typography>
                        </Stack>
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>                    
                    {clientData.fullName || typeContract ? <>                    
                        <PersonField
                            fullWidth
                            object={clientData}
                            value={client}
                            onChange={(client) => {
                                setName(client.fullName)
                                setClient(client.id)
                            }}
                        /></> :
                        <LegalClientField
                            fullWidth
                            object={clientData}
                            value={legalClient}
                            onChange={(client) => {
                                setName(client.name)
                                setLegalClient(client.id)
                            }}
                        />}
                </Grid>
                <Grid item xs={6}>
                    <TextField required value={type} onChange={(e) => {
                        setType(e.target.value)
                    }}
                               margin="normal" fullWidth id="select" label="Тип договора" select>
                        {contractType && isAdditional ? contractType.filter(el => el.code !== 'GovernmentOrder')?.map(el => {
                            return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                        }) : contractType?.map(el => {
                            const disabledKSEK = el.code === "GovernmentOrder" && (!isAdmin || isAdditional)
                            return <MenuItem disabled={disabledKSEK} key={el.id} value={el.id}>{el.name}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                   <Stack sx={{width: '100%', height: '100%'}} direction="row" alignItems="center">
                       <FormControlLabel  control={<Checkbox checked={data?.isDiscount}
                                                                                                 onChange={(event) =>
                                                                                                     handleChangeObject('isDiscount', event.target.checked)}/>}
                                         label="Скидка"/>
                       {data?.isDiscount &&
                       <TextShared required={false} value={data?.discountComment}
                                   setValue={(val) => handleChangeObject('discountComment', val)} label="Скидка (комментарий)"/>
                       }
                   </Stack>
                </Grid>
                {isAdditional && <>
                    <Grid item xs={6}>
                        <FormControlLabel  control={<Checkbox checked={data?.toDecrease}
                                                              onChange={(event) =>
                                                                  handleChangeObject('toDecrease', event.target.checked)}/>}
                                           label="На уменьшение"/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel  control={<Checkbox checked={data?.toIncrease}
                                                              onChange={(event) =>
                                                                  handleChangeObject('toIncrease', event.target.checked)}/>}
                                           label="На увеличение"/>
                    </Grid>
                </>}
            </Grid>
            {type === 5 && <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Филиалы</Typography>
                <ContractFilials/>
            </Box>}
            {type !== 5 && <> <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Вид обучения</Typography>
                <EducationServicesTableContractRequest setEducationServices={setEducationServices}
                                                       educationServiceKind={educationServiceKind}
                                                       localEducationServiceKind={localEducationServiceKind}
                                                       educationServices={educationServices}/>
                <Button onClick={handleEducationServices}>Добавить блок</Button>
            </Box>
            {!typeContract && <Box sx={{
                    margin: '30px 0',
                    width: '100%',
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }}>
                    <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Объекты</Typography>
                    <ContractRequestObject geo={district} contractRequestObject={contractObject}
                                           setContractRequestObject={setContractObject}/>
                    <Button onClick={handleContractObjectRequest}>Добавить блок</Button>
                    {contractObject.length ?
                        <Button onClick={() => openCreateObjectForm()}>Создать объект</Button> : <></>}
                </Box>}
            </>}
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            disabled={typeContract ? disabledPerson : disabledLegal}
                            fullWidth
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? 'Завершить' : 'Далее'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AddDate
