export const initialValueVariant = ({specification, position, proxy, supervisorName = 'Не задано', city, name = 'Не задано', number= 'Не задано', schedules, filial, filialDate, client= 'Не задано', inn= 'Не задано', bin= 'Не задано', date2, date1, price, phoneNumber= 'Не задано', addressLine= 'Не задано', supervisor= 'Не задано', bank= 'Не задано', bankBik= 'Не задано', bankAccountNumber= 'Не задано'}) => `<table style="border-collapse: collapse; width: 100%; border-style: hidden;" border="0">
<tbody>
<tr>
<td style="width: 50.0381%;">
<p style="margin: 0cm 8.75pt 0.0001pt 0cm; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; font-family: 'Times New Roman', serif;">№ ${number} </span></p>
<p style="margin: 0cm 8.75pt 0.0001pt 0cm; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; font-family: 'Times New Roman', serif;">ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫ</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">(керегін жазу) қ.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;${date1}</span></strong></p>
<p style="text-align: justify; text-indent: 22.95pt; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 10.0pt;">&nbsp;</span></strong></p>
<p style="text-align: justify; text-indent: 17.3pt; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Бұдан әрі &laquo;Орындаушы&raquo; деп аталатын, <strong>ҚР ДСМ СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong>, __ 202__ ж. __ Бас сенімхат негізінде әрекет ететін <strong>ҚР ДСМ СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong> филиал ${position} <strong><u>(филиалдың атауы, аты-жөні)</u> </strong>бір тараптан және</span><span style="font-size: 9.0pt; color: black;"> және &nbsp;&nbsp;_____________________________ (жеке тұлға, Т.А.Ә. ), бұдан әрі &laquo;Тапсырыс беруші&raquo; деп аталатын, басқа жағынан, бірлесіп ескергенде &laquo;Тараптар&raquo; , жеке айтқанда &laquo;Тарап&raquo;&nbsp; немесе&nbsp; жоғарыда айтылғандай, осы Шартты (бұдан әрі - шарт) жасасты:</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">1. Шарттың мәні</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">1.1 Орындаушы Тапсырыс берушіге осы Шарттың қосымшасына (бұдан әрі - Қызметтер) сәйкес қызмет көрсетуге міндеттенеді және Тапсырыс беруші Қызметтерді осы Шарттың талаптарына сәйкес қабылдауға және төлеуге міндеттенеді.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">1.2 Төменде келтірілген құжаттар мен онда айтылған шарттар осы Шартты құрайды және оның ажырамас бөлігі болып табылады, атап айтқанда: 1) осы Шарт; 2) сатып алынатын қызметтер тізімі (Қосымша).</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">2. Шарттың құны және төлем шарттары</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">2.1 Шарттың жалпы сомасы Қосымшада көрсетілген және қызметтерді жүзеге асырумен байланысты барлық шығыстарды, сондай-ақ Қазақстан Республикасының заңнамасында көзделген барлық салықтар мен алымдарды қамтиды.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">2.2 Тапсырыс беруші Шарт күшіне енген күннен бастап 3 (үш) жұмыс күн ішінде Орындаушының есеп айырысу шотына/ кассасына&nbsp; 100% көлемде аванстық төлем жасайды.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">2.3 Саны және құны бойынша ұсынылатын Қызметтер сомасы осы Шарттың 1-қосымшасында көрсетілген.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; color: black;">Ескерту: Келесі нұсқаның бірін таңдаңыз:</span></u></em></p>
<ul class="tox-checklist">
<li style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt; color: black;">Қызметтерді көрсету үлгілері Тапсырыс берушімен дербес қамтамасыз етіледі.&nbsp;&nbsp; </span></em></li>
<li style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt; color: black;">Қызмет көрсету үшін мамандардың жол жүруі Тапсырыс берушінің көлік құралдарымен жүзеге асырылады.</span></em></li>
<li style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt; color: black;">Қызметтерді көрсету үшін мамандардың жол жүруі Орындаушының бағаларына сәйкес Орындаушының автокөлігімен жүзеге асырылады.</span></em></li>
</ul>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">3. Тараптардың міндеттері</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">3.1 Орындаушы міндетіне алады:</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">1) Шарт бойынша өзіне алған міндеттемелердің толық және тиесілі орындалуын қамтамасыз етуге, сондай-ақ Тапсырыс берушінің талабы бойынша Шарт бойынша міндеттемелерді орындау барысы туралы ақпарат беруге;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">2) Қызмет көрсету аяқталғаннан кейін және осы Шарттың 2.2-тармағының орындалуына қарай хаттамалар беруге;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">3.2 Тапсырыс беруші міндеттеледі:</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">1) Қызметтерді көрсету үшін Орындаушының мамандарына қол объектіге жетімділікті қамтамасыз ету;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">2) Шарттың талаптарына сәйкес Шарт бойынша Орындаушымен көрсетілетін қызметтерді қабылдайды және төлейді.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">3.3 </span><span style="font-size: 9.0pt;">Шарттың тараптары Қазақстан Республикасының Сыбайлас жемқорлыққа қарсы заңнамасының және Қазақстан Республикасы ратификациялаған сыбайлас жемқорлыққа қарсы іс-қимыл саласындағы халықаралық келісімдердің нормаларын сақтауға міндетті.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.4 Тараптар шарт бойынша қабылданған міндеттемелерді орындау кезінде іскерлік әдеп, парасаттылық нормаларын, сондай-ақ адал бәсекелестіктің негізгі қағидаттарын сақтауға міндетті.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.5 Тараптар шарт жасасу кезінде, сондай-ақ шарт бойынша қабылданған міндеттемелерді орындау процесінде мүдделердің нақты немесе ықтимал қақтығысы туралы мәліметтерді ашуға міндетті.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">4. Тараптардың жауапкершілігі</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">4.1 Тапсырыс беруші осы шарттың 2.2. т. бұзған жағдайда, Орындаушы осы шартты біржақты тәртіпте бұзуға құқылы, ал Тапсырыс беруші Орындаушының барлық шығындарын өтеуге міндеттенеді</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">4.2 Орындаушы тарапынан осы Шартпен көзделген Қызмет көрсету мерзімдері&nbsp; бұзылған жағдайда, Тапсырыс беруші Шарт бойынша өз міндеттемелерін орындауды Орындаушыға нақты көрсетілетін қызметтерді төлей отырып, осы Шарт бойынша міндеттемелерін орындамағаны туралы хабарлама жіберу арқылы тоқтатуға құқылы.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">4.3 Егер қандай да бір өзгеріс Орындаушының Шарт бойынша Қызметтерді көрсетуге қажетті шығындардың немесе мерзімінің азаюына немесе көбеюіне әкелсе, онда Шарттың сомасы немесе Қызмет көрсету мерзімі немесе екеуі де тиісті түрде түзетіледі және Шартқа өзгертулер енгізілген қосымша келісім жасалады.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">5. Шарттың&nbsp; жарамдылық&nbsp; мерзімі және тоқтату негіздері</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">5.1 Шарт қол қойылған күнінен бастап күшіне енеді және </span><span style="font-size: 9.0pt;">202__ жылғы &laquo;__&raquo; _______&nbsp; дейін күшінде болады.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">5.2 Шарт тараптардың келісімі бойынша тоқтатылуы мүмкін.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">5.3. Тараптар форс-мажор жағдайының нәтижесі болған жағдайда, Шарттың талаптарын орындамағаны үшін жауап бермейді.</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; color: black;">6. Басқа жағдайлар</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">6.1 Шартқа кез келген өзгерістер мен толықтырулар тек олар жазбаша нұсқада және Тараптардың осыған өкілетті өкілдері қол қойған және олардың мөрлерімен бекітілген жағдайда ғана шынайы деп танылады.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">6.2 Тапсырыс беруші мен Орындаушы Шарт бойынша немесе онымен байланысты туындаған барлық келіспеушіліктерді немесе келіспеушіліктерді тікелей келіссөздер түрінде шешу үшін барлық күш-жігерін салуы керек.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: black;">6.3 Шартта көдделмеген мәселелер бойынша Тараптар Қазақстан Республикасының заңнамасын басшылыққа алады. </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">7. Тараптардың реквизиттері</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">Орындаушы</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: #212121;">ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК атынан ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК __________________________________________ филиалы</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Мекенжайы: ${filialDate?.addressLine}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">БСН ${filialDate?.bin} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">ЖСК KZ${filialDate?.bankAccountNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">БСК ${filialDate?.bankBik}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">КБе ${filialDate?.beneficiaryCode}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Тел ${filialDate?.phoneNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Директор&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">${supervisor} </span><span style="font-size: 9.0pt;">Т.А.Ә.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span><span style="font-size: 8.0pt;">М.О.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">Тапсырыс беруші </span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; color: #212121;">________________</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Мекенжайы: _____________________________</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">БСН ${inn}/${bin} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">ЖСК KZ${bankAccountNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Банк ${bank}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">БСК ${bankBik}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">КБе _______________</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">Тел ${phoneNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">____________________ </span><span style="font-size: 9.0pt;">Т.А.Ә.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span><span style="font-size: 9.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Қолы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style="font-size: 8.0pt;">М.О.</span></p>
</td>
<td style="width: 50.0381%;">
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">ДОГОВОР ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ  </span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">№ ${number}</span></strong></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">г. (<u>вписать нужное</u>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${date1}</span></strong></p>
<p style="text-align: justify; text-indent: 22.95pt; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; text-indent: 24.4pt; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">РГП на ПХВ &laquo;Национальный центр экспертизы&raquo; КСЭК МЗ РК, </span></strong><span style="font-size: 9.0pt;">именуемое в дальнейшем &laquo;Исполнитель&raquo;, в лице <strong>${position} филиала ${filial},</strong> ${supervisor} действующего на основании ${proxy?.proxy} №${proxy?.proxyNumber} от ${proxy?.proxyDate} с одной стороны, и ${client}, именуемое в дальнейшем &laquo;Заказчик&raquo;, с другой стороны, при совместном упоминании именуемые </span><span style="font-size: 9.0pt;">&laquo;</span><span style="font-size: 9.0pt;">Стороны</span><span style="font-size: 9.0pt;">&raquo;</span><span style="font-size: 9.0pt;">, а по отдельности </span><span style="font-size: 9.0pt;">&laquo;</span><span style="font-size: 9.0pt;">Сторона</span><span style="font-size: 9.0pt;">&raquo;</span><span style="font-size: 9.0pt;"> или как указано выше, заключили настоящий договор (далее - Договор) о нижеследующем:</span></p>
<p style="text-align: justify; text-indent: 35.4pt; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">1. Предмет договора</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">1.1 Исполнитель обязуется оказать Заказчику услуги согласно приложению к настоящему Договору (далее &ndash; Услуги), а Заказчик оплатить и принять Услуги на условиях настоящего Договора.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">1.2 Перечисленные ниже документы и условия, оговоренные в них, образуют данный Договор и считаются его неотъемлемой частью, а именно: 1) настоящий Договор, 2) перечень закупаемых услуг (Приложение).</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">2. Стоимость Договора и условия оплаты</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">2.1 Общая сумма Договора указана в Приложении к настоящему Договору и включает все расходы, связанные с выполнением услуг, а также все налоги и сборы, предусмотренные законодательством Республики Казахстан. </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">2.2 Заказчик в течение 3 (трех) рабочих дней со дня вступления Договора в силу, производит на расчетный счет/кассу Исполнителя авансовый платеж в размере 100%.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">2.3 Объем оказываемых Услуг в количественном и стоимостном выражении оговорен в Приложении к Договору.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt;">Примечание: Выберите один из следующих вариантов:</span></u></em></p>
<ul class="tox-checklist">
<li class="" style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">Пробы для оказания Услуг предоставляются Заказчиком самостоятельно. </span></em></li>
<li class="" style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">&nbsp;Выезд специалистов для оказания услуг осуществляется на автотранспорте Заказчика.</span></em></li>
<li style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">&nbsp;Выезд специалистов для оказания услуг осуществляется на автотранспорте Исполнителя, согласно Прейскуранту цен Исполнителя.</span></em></li>
</ul>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">3. Обязательства Сторон</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.1 Исполнитель обязуется:</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">1) обеспечить полное и надлежащее исполнение взятых на себя обязательств по Договору, а также по требованию Заказчика предоставлять информацию о ходе исполнения обязательств по Договору;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">2) выдать протоколы результатов по завершении оказания услуг и при условии исполнения Заказчиком п. 2.2 настоящего Договора;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.2 Заказчик обязуется:</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">1) обеспечить доступ к объекту специалистам Исполнителя для оказания Услуг;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">2) в соответствии с условиями Договора принимать и оплачивать Услуги, оказанные Исполнителем по Договору;</span></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.3 Стороны договора обязаны соблюдать нормы антикоррупционного законодательства Республики Казахстан и международных соглашений, ратифицированных Республикой Казахстан в области противодействия коррупции.</span></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.4 Стороны, при исполнении принятых обязательств по договору обязаны соблюдать нормы деловой этики, добропорядочности, а также основные принципы добросовестной конкуренции.</span></p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">3.5 Стороны, при заключении договора, а также в процессе исполнения принятых обязательств по договору обязаны раскрывать сведений о реальном или потенциальном конфликте интересов.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">4. Ответственность сторон</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">4.1 В случае нарушения Заказчиком п. 2.2. настоящего договора, Исполнитель вправе расторгнуть настоящий договор в одностороннем порядке, а Заказчик обязуется возместить все убытки Исполнителя.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">4.2 В случае нарушения установленных Договором сроков оказания Услуг со стороны Исполнителя, Заказчик вправе прекратить исполнение своих обязательств по Договору, направив уведомление о неисполнении обязательств по настоящему договору, оплатив Исполнителю фактически оказанные услуги.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">4.3 Если любое изменение ведет к уменьшению или увеличению стоимости, или сроков, необходимых Исполнителю для оказания Услуг по Договору, то сумма Договора или срок оказания Услуг, или и то и другое соответствующим образом корректируется, а к Договору составляется Дополнительное соглашение с внесенными поправками. </span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">&nbsp;</span></strong></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">5. Срок действия и условия расторжения договора</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">5.1 Договор вступает в силу со дня подписания и действует </span><span style="font-size: 9.0pt;">по ${date2}.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">5.2 Договор может быть расторгнут по соглашению сторон.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">5.3. Стороны не несут ответственность за неисполнение условий Договора, если оно явилось результатом форс-мажорных <br />обстоятельств.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">6. Прочие условия</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">6.1 Любые изменения и дополнения к Договору действительны лишь при условии, если они совершены в письменной форме и подписаны Сторонами. </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">6.2 Заказчик и Исполнитель должны прилагать все усилия к тому, чтобы разрешать в процессе прямых переговоров все разногласия или споры, возникающие между ними по Договору или в связи с ним.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">6.3 Неурегулированные вопросы Договором, Стороны руководствуются законодательством Республики Казахстан. </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">7. Реквизиты сторон</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 10.0pt;">Исполнитель</span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">${filial}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Адрес: ${filialDate?.addressLine}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">БИН ${filialDate?.bin} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">ИИК KZ${filialDate?.bankAccountNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">БИК ${filialDate?.bankBik}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">КБе ${filialDate?.beneficiaryCode}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Тел ${filialDate?.phoneNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Директор&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">${supervisor} Ф.И.О.</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 8.0pt;">М.П.</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 8.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 8.0pt;">&nbsp;</span></p>
<p style="text-align: center; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 8.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt;">Заказчик </span></strong></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">${client}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Адрес: ${addressLine}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">ИИН/БИН ${inn ? inn : bin} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">ИИК KZ${bankAccountNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Банк ${bank}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">БИК ${bankBik}</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">КБе ____</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">Тел ${phoneNumber} </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">${supervisorName} Ф.И.О.</span></p>
<p style="text-align: justify; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 10.0pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Подпись</span></p>
<span style="font-size: 9.0pt; font-family: 'Times New Roman', serif;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; М.П.</span></td>
</tr>
</tbody>
</table>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt; color: black;">&nbsp;</span></em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>Приложение</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>&nbsp; к Договору</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>№${number} </em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>&nbsp;&nbsp;&nbsp; от ${date1}</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;">&nbsp;</p>
<p style="text-align: center;"><strong>Перечень закупаемых услуг</strong></p>
<table style="border-collapse: collapse; width: 99.8328%; border-style: solid;" border="1">
<tbody>
<tr>
<td style="width: 12.5105%;" width="42">
<p><strong>№</strong></p>
</td>
<td style="width: 12.5105%;" width="68">
<p><strong>Вид услуги</strong></p>
</td>
<td style="width: 12.5105%;" width="113">
<p><strong>Наименование услуги/анализа</strong></p>
</td>
<td style="width: 12.5105%;" width="72">
<p><strong>Единица измерения</strong></p>
</td>
<td style="width: 12.5105%;" width="68">
<p><strong>График</strong></p>
</td>
<td style="width: 12.5945%;" width="72">
<p><strong>Количество </strong></p>
</td>
<td style="width: 12.5105%;" width="49">
<p><strong>Цена</strong></p>
</td>
<td style="width: 12.3426%;" width="64">
<p><strong>Сумма</strong></p>
</td>
</tr>
${specification && specification.map((el, idx) => {
    return `<tr>
                    <td style="width: 11.1079%;">
                        ${idx + 1}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.service?.category?.name || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.service?.name || el.group?.name || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.unit?.name ? el.unit?.name : el.unit || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el?.schedule?.name || ''}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.quantity || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.price || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${((el.quantity ? el.quantity : 1) * el.price).toString()}
                    </td>
                </tr>`
})}
</tbody>
</table>
<table style="border-collapse: collapse; width: 99.1639%; border-style: hidden;" border="0">
<tbody>
<tr>
<td style="width: 51.4786%;">
<ol start="8">
<li><strong>8. Тараптардың реквизиттері</strong></li>
</ol>
<p><strong>Орындаушы</strong></p>
<p>ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК атынан ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК _________________________ филиалы</p>
<p>Мекенжайы: ${filialDate?.addressLine}</p>
<p>БСН ${filialDate?.bin}</p>
<p>ЖСК KZ${filialDate?.bankAccountNumber}</p>
<p>&nbsp;</p>
<p>БСК ${filialDate?.bankBik}</p>
<p>КБе ${filialDate?.beneficiaryCode}</p>
<p>Тел ${filialDate?.phoneNumber}</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>${supervisor} Т.А.Ә.</p>
<p>&nbsp;М.О.</p>
<p><strong>Тапсырыс беруші </strong></p>
<p>________________</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ${inn}/${bin}</p>
<p>ЖСК KZ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БСК ${bankBik}</p>
<p>КБе _______________</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Қолы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; М.О.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</td>
<td style="width: 48.52%;">
<ol start="8">
<li><strong>8. Реквизиты сторон</strong></li>
</ol>
<p><strong>Исполнитель</strong></p>
<p>${filial}</p>
<p>Адрес: ${filialDate?.addressLine}</p>
<p>БИН ${filialDate?.bin}</p>
<p>ИИК KZ${filialDate?.bankAccountNumber}</p>
<p>&nbsp;</p>
<p>БИК ${filialDate?.bankBik}</p>
<p>КБе ${filialDate?.beneficiaryCode}</p>
<p>Тел ${filialDate?.phoneNumber}</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>${supervisor} Ф.И.О.</p>
<p>М.П.</p>
<p><strong>Заказчик </strong></p>
<p>${client}</p>
<p>Адрес: ${addressLine}</p>
<p>ИИН/БИН ${inn ? inn : bin}</p>
<p>ИИК KZ ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БИК ${bankBik}</p>
<p>КБе ____</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>${supervisorName} Ф.И.О.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Подпись</p>
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>`

export const initialValueLegalContract = ({specification, proxy, supervisorName = 'Не задано', position, city, name = 'Не задано', number= 'Не задано', schedules, filial, filialDate, client= 'Не задано', inn= 'Не задано', bin= 'Не задано', date2, date1, price, phoneNumber= 'Не задано', addressLine= 'Не задано', supervisor= 'Не задано', bank= 'Не задано', bankBik= 'Не задано', bankAccountNumber= 'Не задано'}) => `<table style="border-collapse: collapse; width: 98.2397%; height: 6171.8px; border-style: hidden;" border="0">
<tbody>
<tr style="height: 6171.8px;">
<td style="width: 52.3492%; height: 6171.8px;">
<table class="MsoNormalTable" style="border-collapse: collapse; width: 100%; height: 6282px; border-style: hidden;" border="1" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr style="height: 3.2pt;">
<td style="width: 100%; border: 1pt solid windowtext; padding: 0cm 5.4pt;" valign="top">
<p style="margin: 0cm 8.75pt 0.0001pt 0cm; line-height: 106%; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; line-height: 106%; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
<p style="margin: 0cm 8.75pt 0.0001pt 0cm; line-height: 106%; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; line-height: 106%; font-family: 'Times New Roman', serif;">№ ${number} </span></p>
<p style="margin: 0cm 8.75pt 0.0001pt 0cm; line-height: 106%; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; line-height: 106%; font-family: 'Times New Roman', serif;">ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫ</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">(керегін жазу) қ.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${date1}</span></strong></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 10.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Бұдан әрі &laquo;Орындаушы&raquo; деп аталатын, <strong>ҚР ДСМ СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong>, __ 202__ ж. №__&nbsp; Бас сенімхат негізінде әрекет ететін <strong>ҚР ДСМ &nbsp;СЭБК</strong> &nbsp;<strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong> филиал ${position} <strong><u>(филиалдың атауы, аты-жөні)</u> </strong>бір тараптан және&nbsp; бұдан әрі &laquo;Тапсырыс беруші&raquo; деп аталынатын екінші тараптан<strong> (<u>ұйымдық-құқықтық нысан және заңды тұлғаның фирмалық атауы</u>) </strong>&nbsp;<strong>(құжаттың атауы)</strong> негізінде әрекет ететін (аты-жөні, лауазым) атынан, бірлесіп ескергенде &laquo;Тараптар&raquo;, жеке айтқанда &laquo;Тарап&raquo; немесе жоғарыда айтылғандай осы Шартты (бұдан әрі - Шарт) жасасты және төмендегілер туралы келісімге келді:</span></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">1. Шарттың мәні</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1.1 Орындаушы Тапсырыс берушіге осы Шарттың қосымшасына сәйкес, қызмет (бұдан әрі &ndash; Қызмет) көрсетуге, ал Тапсырыс беруші Қызметтерді осы Шарт талаптарына сай қабылдауға және төлеуге міндеттенеді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1.2 Төменде келтірілген құжаттар мен онда айтылған шарттар осы Шартты құрайды және оның ажырамас бөлігі болып табылады, атап айтқанда:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1) осы Шарт;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2) сатып алынатын қызметтер тізімі (Қосымша);</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;">2. Шарттың құны және ақы төлеу шарттары</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2.1 Шарттың жалпы сомасы </span><strong><span style="font-size: 9.0pt; line-height: 106%;">_____ (_______________)</span></strong><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;"> теңге</span></strong><span style="font-size: 9.0pt; line-height: 106%; color: black;"> қызметтерді орындаумен байланысты барлық шығындар, сондай-ақ Қазақстан Республикасының заңнамасымен қарастырылған барлық салықтар мен алымдарды қамтиды.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2.2 Соманы Тапсырыс беруші Орындаушы көрсетілетін Қызметтерді, осы </span><span style="font-size: 9.0pt; line-height: 106%; color: black;">Шарттың қосымшасына сәйкес факт бойынша</span><span style="font-size: 9.0pt; line-height: 106%; color: black;"> көрсеткеннен кейін және көрсетілген Қызметтер актісіне Тараптар қол қойғаннан кейін 30 (отыз) күнтізбелік күн ішінде аударым түрінде төлейді. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">Қызметтерді қабылдап алу-беру актісінің нысанын алдын ала Тапсырыс берушімен келісіледі.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">Тапсырыс берушінің аванстық төлем жүргізуге құқығы бар. Қалған соманы Тапсырыс беруші бұрын төленген аванстық төлемді ұстауды ескере отырып, көрсетілген қызметтер актісіне Тараптар қол қойған күннен бастап 30 (отыз) күнтізбелік күннен кешіктірмей Орындаушының ағымдағы шотына ақша аудару арқылы төлейді. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2.3 Орындалатын Қызметтердің көлемі сандық және бағалық түрінде Шарттың Қосымшасында көрсетілген.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2.4 Ақы төлеу алдындағы қажетті құжаттар:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">1) қол қойылған Шарт;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) орындалған Жұмыстардың актісі;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3) </span><span style="font-size: 9.0pt; line-height: 106%;">Өнім беруші<span style="color: black;"> тапсырыс берушіге ұсынған төлем шоты.</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.5. Қызмет көрсету ушін мамандардың жол жүруі Орындаушының автокөлігінде Орындаушының бағанамасына сәйкес жүзеге асырылады Тапсырыс берушінің автокөлігіне шыққан жағдайда шығу өтеусіз негізде жүзеге асырылады. Егер </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушының</span><span style="font-size: 9.0pt; line-height: 106%;"> қызметкерлері 60 км-ден астам қашықтыққа бір бағытта жүрсе, Тапсырыс беруші Орындаушының Прейскурантына сәйкес іссапар шығыстарын төлейді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;">3. Тараптардың жауапкершілігі</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3.1 </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушы<span style="color: black;"> міндетіне алады:</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">1) Шарт бойынша өзіне алған міндеттемелердің толық және тиесілі орындалуын қамтамасыз етуге;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) Тапсырыс берушінің бірінші талабы бойынша Шарт бойынша міндеттемелердің орындалу барысы туралы ақпарат ұсынуға;</span><strong><span style="font-size: 9.0pt; line-height: 106%;"> </span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3) Көрсетілген қызметтер үшін толық төлем алынғаннан кейін Тапсырыс берушіге зерттеу хаттамаларын беруге міндетті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">Ескерту: дезинфекциялық қызмет</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">терді</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;"> көрсеткен жағдайда 3.</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">1</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">-тармағы 3 тармақшасының нормасы келісім мәтінінен алынып тасталуы </span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">тиіс</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.2 Тапсырыс беруші:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1) Қызметтерді көрсету үшін <span style="color: black;">Тапсырыс берушінің</span> мамандарының қол жеткізуін қамтамасыз ету міндетіне алады;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2) Шарттың талаптарына сәйкес <span style="color: black;">Тапсырыс берушінің</span> Шарт бойынша көрсетілген қызметтерді қабылдап алуға және төлеу міндетіне алады;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3) Осы келісімшартты үшінші тұлғаларға қатысты міндеттемелерді орындау үшін (қосалқы мердігерлік) пайдалануға болмайды;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.3 Өнімдерді зертханалық зерттеудің қанағаттанарлықсыз нәтижесі кезінде Тараптар қосымша келісім жасасуға міндеттенеді, үлгілердің екі еселенген мөлшерін пайдалана отырып, технологиялық үдеріс барысы бойынша өндіріске, шикізатқа, жартылай дайындалған өнімге, қосалқы материалдарға, суға, барлық жұмыс үй-жайларының, санитарлық- гигиеналық жағдайына қосымша бақылау жүргізіледі </span><span style="font-size: 9.0pt; line-height: 106%;">және </span><span style="font-size: 9.0pt; line-height: 106%;">тиісті санитарлық-гигиеналық және эпидемияға қарсы іс-шаралар жүргізілуіне қайта зерттеледі. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">Ескерту: дезинфекциялық қызмет</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">терді</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;"> көрсеткен жағдайда 3.3-тармағының нормасы келісім мәтінінен алынып тасталуы </span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">тиіс</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.4 </span><span style="font-size: 9.0pt; line-height: 106%;">Шарттың тараптары Қазақстан Республикасының Сыбайлас жемқорлыққа қарсы заңнамасының және Қазақстан Республикасы ратификациялаған сыбайлас жемқорлыққа қарсы іс-қимыл саласындағы халықаралық келісімдердің нормаларын сақтауға міндетті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.5 Тараптар шарт бойынша қабылданған міндеттемелерді орындау кезінде іскерлік әдеп, парасаттылық нормаларын, сондай-ақ адал бәсекелестіктің негізгі қағидаттарын сақтауға міндетті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.6 Тараптар шарт жасасу кезінде, сондай-ақ шарт бойынша қабылданған міндеттемелерді орындау процесінде мүдделердің нақты немесе ықтимал қақтығысы туралы мәліметтерді ашуға міндетті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">4. Қызметтердің техникалық ерекшелікке сәйкестілігін тексеру</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.1 Берілген Келісім-шарт шегінде жеткізілетін Қызметтер техникалық ерекшелікте көрсетілген стандарттарға сәйкес немесе олардан жоғары болуы қажет.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.2 Егер тексеріс кезінде Қызметтердің нәтижелері техникалық ерекшеліктің талаптарына сәйкес емес деп танылса, Орындаушы, Тапсырыс беруші жағынан еш қосымша шығынсыз, техникалық ерекшелік талаптарына сәйкессіздіктерді жою бойынша шараларды қолданады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.3 Жоғарыда көрсетілген ешбір тармақ Орындаушыны Келісім-шарт бойынша басқа міндеттерден босатпайды.<strong><span style="color: black;"> </span></strong></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;">5. Қызметтерді көрсету және құжаттама</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.1 Орындаушының қызметтерді көрсетуі Шарттың ажырамас бөлігі болып табылатын Шарт Қосымшасында көрсетілген мерзімдерде жүзеге асырылады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.2 Көрсетілген қызметтерді есептеу үшін Орындаушы келесі құжаттарды ұсынуы керек:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">1) екі данада көрсетілген Қызметтер актілері;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) көрсетілген Қызметтердің жалпы сомасы, көрсете отырып сипаттайтын шот-фактура.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.3. Қызмет көрсету мерзімі &ndash; </span><span style="font-size: 9.0pt; line-height: 106%; color: black;">202</span><span style="font-size: 9.0pt; line-height: 106%;">__</span><span style="font-size: 9.0pt; line-height: 106%; color: black;"> ж. </span><span style="font-size: 9.0pt; line-height: 106%;">&laquo;___&raquo; ___________</span><span style="font-size: 9.0pt; line-height: 106%;"> нан <span style="color: black;">202</span></span><span style="font-size: 9.0pt; line-height: 106%;">__</span><span style="font-size: 9.0pt; line-height: 106%; color: black;"> ж. </span><span style="font-size: 9.0pt; line-height: 106%;">&laquo;___&raquo; ___________</span><span style="font-size: 9.0pt; line-height: 106%;"> дейін.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.4. Қызмет көрсету орны:</span><span style="font-size: 9.0pt; line-height: 106%;"> Шарт қосымшасына сәйкес</span><span style="font-size: 9.0pt; line-height: 106%; color: black;">.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">5.5. Осы келісімшарттың қосымшасында белгіленген нақты талаптарға сәйкес Орындаушы Тапсырыс берушіге қызметті толығымен тапсырған жағдайда қызмет орындалған деп саналады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">6. Кепілдік</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.1 Орындаушы Тапсырыс берушіге үздіксіз, сапалы және уақытылы Қызмет көрсетуді кепілдік етеді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.2 Орындаушы техникалық ерекшеліктің (Шарт Қосымшасы) Қызметтерінің қателерін, жете өңдеулері мен басқа сәйкессіздіктерін ақысыз түзетуді кепілдендіреді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.3 Тапсырыс беруші берілген кепілдікке байланысты барлық шағымдары туралы Орындаушыға жазбаша түрде жедел хабарлауға міндетті, одан кейін Орындаушы, осыған байланысты барлық шығындармен бірге, ақаулықтарды жою бойынша шараларды Тапсырыс беруші хабарламасында көрсеткен мерзім ішінде қолдануы қажет.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.4 Шартқа қол қойғаннан кейін Тапсырыс беруші не оның өзі, не оның қатысушылары/акционерлері және түпкілікті иелері Орындаушыға қатысты байланысты тарабы болып табылмайтынына сөзсіз кепілдік береді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">7. Құпиялылық шарттары</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.1 осы Шарттың шеңберінде Тараптардың өзара келісімі бойынша осы Келісімшартты шартты орындау барысында белгілі болған кез келген ақпарат құпия болып саналады, оның ішінде бірақ осымен шектелмей Орындаушының алынған нәтижелері, құрылтай құжаттары, рұқсат құжаттары.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.2 Тараптардың әрқайсысы осы Шарттың шеңберінде оған белгілі болған құпия ақпаратты рұқсат берілмеген пайдалануға, таратудан немесе жарияланудан қорғауды қамтамасыз етуге міндетті</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.3 Екінші тараптың алдынала жазбаша мақылдаусыз тура немесе жанама түрде қандай да бір нысанда немесе қандай да бір құралдармен құпия ақпаратты немесе оның бір бөлігін үшінші таратпауға, ашпауға немесе жарияламауға Тараптар келісті,</span><span style="font-size: 14.0pt; line-height: 106%;"> </span><span style="font-size: 9.0pt; line-height: 106%;">Қазақстан Республикасының заңнамасында көзделгендерден басқа.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.4 Құпиялылық шарттар осы Келісімшартқа қол қойылғаннан бастап күшіне енеді және Келісімшарттың қолданылу мерзімі аяқталғанннан кейін 3 (үш) жыл ішінде әрекет етеді. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.5 Әрбір Тараптар өз қызметкерлеріне өздерінің қызметтік міндеттерін атқару үшін қажетті құпия ақпараттың барлық және/немесе бір бөлігін жария етуге немесе беруге құқылы туралы Тараптар келісті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.6 Тараптар тоқтатылған Келісімшарттың ажырамас бөлігі болып табылатын Бұзу туралы шартқа қол қойылған сәттен бастап Келісімшарттың заңды күшке ие емес екендігімен келісті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.7 Тапсырыс берушінің, осы Келісімшарпен қарастырылған негіздер бойынша бұзылған Келісімшартты үшінші тапарапқа берген жағдайда, Келісімшарттың 8 бөліміне сәйкес жауапкершілікке тартылатындығымен келісті.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">8. Тараптардың жауапкершілігі</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.1 Тапсырыс беруші осы Келісімшарттың 7 бөлімін, 6.4-тармағын, 3.2-тармақтың 3) тармақшасын бұзған жағдайда, Орындаушы осы Келісімшартты </span><span style="font-size: 9.0pt; line-height: 106%;">біржақты тәртіпте бұзуға құқылы, Тапсырыс беруші Келісімшарттың жалпы сомасынан 50% мөлшерінде айыппұл төлеуге және Орындаушының залалдарын өтеуге міндетті. Тапсырыс беруші Шарттың 7.7 тармағының талаптарын бұзған жағдайда Тапсырыс беруші шарттың жалпы сомасының 50% мөлшерінде айыппұл төлейді және Орындаушының барлық шығындарын өтейді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.2 </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушы тарапынан Қызметтерді көрсету шарттары бұзылған жағдайда, Тапсырыс беруші Шарт бойынша өз міндеттемелерін орындауды осы келісімшарт бойынша міндеттемелерді орындамағаны туралы жазбаша хабарлама жіберіп, Орындаушыға нақты көрсетілген қызметтерді төлеу арқылы тоқтатуға құқылы<span style="color: black;">.</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.3 Форс-мажорлық жағдайларды есептемегенде, Орындаушы Шартпен қаралған мерзімдерде Қызметті көрсете алмайтын болса, Тапсырыс беруші өзінің басқа құқықтарына залал келтірмей Шарттың шеңберінде мерзімі кешіктірілген әр күн үшін өнім беруші міндеттемені орындамаған жағдайда шарттың соммасынан 0,1 (нөл бүтін бір) пайыз мөлшерінде, бірақ Келісімшарттың жалпы сомасынан 10% артық емес тұрақсыздық айыбын (айыппұл, өсімақы) өндіреді немесе мерзімі кешіктірілген әр күн үшін тиісті түрде орындалмаған міндеттеменің сомасына 0,1 (нөл бүтін бір) пайыз мөлшерінде, бірақ Келісімшарттың жалпы сомасынан 10% артық еместұрақсыздық айыбын (айыппұл, өсімақы) өндіреді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.4 Тұрақсыздықты төлеу </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушы<span style="color: black;"> Шарт бойынша өзінің міндеттерін орындаудан босатпайды.</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.5 <span style="color: black;">Осы Келісімшарттың 2.2-тармағын бұзған жағдайда, Тапсырыс беруші</span> <span style="color: black;">кешіктірілген әр күн үшін&nbsp; шарттың соммасынан </span>0,1% (нөл бүтін оннан бір) (айыппұл, өсіпұл) бірақ Келісімшарттың жалпы сомасынан 10 % артық емес тұрақсыздық айыпты төлейді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.6 Шарт бойынша жұмыстарды орындау үшін </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушыға<span style="color: black;"> қажетті өзгерістер құнның немесе мерзімнің өсуіне немесе азаюына әкелетін болса, онда Келісімшарттың сомасы немесе Қызметтің көрсету мерзімі, немесе екеуіде тиісті тәртіппен түзетіледі, сондай-ақ Келісімшартқа енгізілген түзетулермен қосымша келісім жасалады.</span>Орындаушының<span style="color: black;"> түзетулерді жүргізу туралы барлық сұранымдары </span>Орындаушы<span style="color: black;"> Тапсырыс берушіден өзгерістер туралы тапсырма алған күннен бастап 5 (бес) күн ішінде ұсынылуы тиіс.</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.7 </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушы<span style="color: black;"> Тапсырыс берушінің алдын ала жазбаша келісімінсіз осы Шарт бойынша өзінің міндеттемелерін біреуге толықтай немесе ішінара табыстамауы тиіс. </span>Орындаушы<span style="color: black;"> Тапсырыс берушіге осы Шарт аясында жасалған барлық қосалқы мердігерлік шарттардың көшірмелерін ұсынуы тиіс. </span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">8.8 Қосалқы мердігерлердің болуы </span><span style="font-size: 9.0pt; line-height: 106%;">Орындаушыны<span style="color: black;"> Шарт бойынша материалдық немесе басқа жауапкершіліктен босатпайды.</span></span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">9. Шарттың қолданылу мерзімі және </span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">бұзылу шарттары</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.1 Осы Шарт қол қойған күннен бастап күшіне енеді және 202__ жылғы &laquo;__&raquo; _______&nbsp; дейін күшінде болады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.2 Тапсырыс беруші кез келген уақытта біржақты ретте егер Орындаушы банкрот немесе төлеуге қабілетсіз болып қалған жағдайда, Орындаушыға тиісті жазбаша хабарлама жіберіп Шарттың&nbsp; талаптарын орындаудан бас тарта алады. Бұл жағдайда Шарттың талаптарын орындаудан бас тарту тез арада жүзеге асырылады, және Тапсырыс беруші егер Шарттың талаптарын орындаудан бас тарту ешқандай залал келтірмесе немесе артынан Тапсырыс берушіге қойылатын санкцияларды қолдану немесе әрекеттерді жасауға қандай-да бір құқықтарға тиіспесе, Орындаушыға қатысты ешқандай қаржылық міндеттілікті көтермейді.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.3 Орындаушы кез келген уақытта біржақты ретте егер Қызмет көрсету әрі қарай тиімді емес болған жағдайда Тапсырыс берушіге тиісті жазбаша хабарлама жіберіп Шарттың талаптарын орындаудан бас тарта алады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.4 Осы шарттың 9.2 тармағы негізінде Шарттың күші жойылған кезде, Орындаушы Шарт бойынша бұзуға байланысты оны бұзатын күнгi iс жүзiндегi шығындар үшiн ғана ақы талап етуге құқылы.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.5 Қандай да бір санкцияларға зиян келтірместен Тапсырыс беруші Шарт талаптарын бұзғаны үшін, Өнім берушіге міндеттемелерін орындамағаны туралы жазбаша хабарлама жібере отырып егер Орындаушы Қызметті Шартта көзделген мерзімде немесе Тапсырыс берген Шарттың ұзартылған кезеңі ішінде көрсете алмаса.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">9.6 Шартты тараптардың келісімі бойынша бұзуға болады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">9.7 Жоғарыда көрсетілген жағдайларда, Өнім берушінің өкілі Тапсырыс беруші Шарттың әр парағына осы бөлімде қарастырылған қандай-да бір себептермен Келісімнің тоқтатылғанын растайтын жазба жасайды.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">10. Форс-мажор</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">10.1 Тараптар Шарт талаптарының орындалмауына жауапкершілік көтермейді,егер ол форс-мажорлық жағдайлардың нәтижесі болып табылса.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">10.2 Орындаушы Шарт орындалуының өз қамтамасыз етуінен айырылмайды және айып төлем өтеу үшін немесе Шарт талаптарын орындамау себебінен оны бұзу үшін жауапты болмайды, егер Шарт орындау бойынша кідіріс форс-мажорлық жағдайлардың нәтижесі болып табылса.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">10.3 Шарт мақсаттары үшін &laquo;форс-мажор&raquo; Тараптар бақылауымен биліксіз және күтпеген сипатқа ие оқиғаны білдіреді. Ондай оқиғалар келесілерді қамти алады, бірақ тек олар ғана емес: соғыс қимылдары, табиғи немесе стихиялық апаттар және басқалар.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">10.4 Форс-мажорлық жағдайлардың пайда болуы кезінде Орындаушы Тапсырыс берушіге ондай жағдайлар мен себептері туралы жазбаша хабарламаны жедел жіберуі тиіс. Егер Тапсырыс берушіден басқа жазбаша нұсқаулықтар келіп түспесе, Орындаушы Шарт бойынша өз міндеттерін мүмкіндігінше орындауды жалғастырады және форс-мажорлық жағдайларға байланысты емес, Шартты орындаудың баламалы әдістерін іздейді.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">11. Даулы мәселелерді шешу</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">11.1 Тапсырыс беруші мен Орындаушы Шарт бойынша немесе оған байланысты барлық даулар мен келіспеушіліктерді тікелей келіссөз жүргізу барысында шешу үшін барлық күштерін салулары тиіс.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">11.2 Егер осындай келіссөз басталғаннан кейін 21 (жиырма бір) күнтізбелік күн ішінде Тапсырыс беруші мен Орындаушы Шарт бойынша мәселені шеше алмаса, тараптардың кез келгені осы мәселенің Қазақстан Республикасының заңнамасына сәйкес шешілуін талап ете алады.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">12. Хабарлама</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">12.1 Шартқа сәйкес, бір тараптан екінші тарапқа жолдауға кез келген хабарлама келесіде түпнұсқасын ұсынумен, хат, жедел хат, телекс, факс немесе электрондық поштаға арқылы жіберіледі. Тараптардың электронды мекен-жайлары осы Шарттың 14 бөлімінде көрсетіледі.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">12.2 Хабарлама жеткізілгеннен кейін немесе көрсетілген күшіне ену күнінен (хабарламада көрсетілсе) бастап, осы екі мерзімнің қайсысы кеш келетініне байланысты өз күшіне енеді.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">13. Өзге шарттар</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.1 Шартқа кез келген өзгерістер мен толықтырулар тек олар жазбаша нұсқада және Тараптардың осыған өкілетті өкілдері қол қойған және олардың мөрлерімен бекітілген жағдайда ғана шынайы деп танылады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.2 <span style="color: black;">Қосымша келісімдер тек Тапсырыс беруші келісімшарттың түпнұсқасын ұсынғаннан кейін жасалады;</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.3 Тараптардың біреуімен Шарт бойынша міндеттерін тапсыру тек басқа Жақтың жазбаша рұқсатымен ғана жүзеге асырылады.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.4 Шарт бірдей заңды күшіне ие мемлекеттік және ресми тілінде екі нұсқада құрастырылған, әр Тарап үшін бір нұсқадан.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.5 Шартпен реттелмеген бөлігінде Тараптар Қазақстан Республикасының заңнамасына сүйенеді. </span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">14. Тараптардың реквизиттер</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">Орындаушы</span></strong></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">ҚР ДСМ <strong>&nbsp;СЭБК</strong> &nbsp;&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК </span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">атынан әрекет ететін ҚР ДСМ <strong>&nbsp;СЭБК</strong> &nbsp;&laquo;Ұлттық сараптама </span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">орталығы&raquo; ШЖҚ РМК ____________________ филиалы </span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Мекен-жайы: ________________________________</span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БСН ${filialDate?.bin} </span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">ЖСК KZ${filialDate?.bankAccountNumber} </span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">АҚ </span></p>
<p style="margin: 0cm 14.15pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БСК ${filialDate?.bankBik} </span></p>
<p style="margin: 0cm 0cm 0.0001pt 18pt; text-indent: -18pt; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">КБе ${filialDate?.beneficiaryCode} </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Тел ${filialDate?.phoneNumber} </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">e</span><span style="font-size: 9.0pt; line-height: 106%;">-</span><span style="font-size: 9.0pt; line-height: 106%;">mail</span><span style="font-size: 9.0pt; line-height: 106%;">:___________________</span></p>
<p style="margin: 0cm 14.15pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;<strong>Директор&nbsp; </strong></span></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">${supervisor} <u>&nbsp;</u></span></strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;<strong><u>Т.А.Ә.</u></strong></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">М.О.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">Тапсырыс беруші</span></strong></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&laquo;_____________&raquo;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Мекен-жайы:: __________________</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БСН </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Банк</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">ЖСК </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БСК&nbsp; </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Тел. </span><span style="font-size: 11.0pt; line-height: 106%;">${phoneNumber}</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">e</span><span style="font-size: 9.0pt; line-height: 106%;">-</span><span style="font-size: 9.0pt; line-height: 106%;">mail</span><span style="font-size: 9.0pt; line-height: 106%;">:___________________</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 11.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">Директор</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">_______________</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">М.П.</span></p>
</td>
</tr>
</tbody>
</table>
</td>
<td style="width: 47.6523%; height: 6171.8px;">
<p><strong>&nbsp;</strong></p>
<table class="MsoNormalTable" style="border-collapse: collapse; width: 100%; height: 6323px; border-style: hidden;" border="1" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr style="height: 3.2pt;">
<td style="width: 544.6px; border: 1pt solid windowtext; padding: 0cm 5.4pt;" valign="top">
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">ДОГОВОР ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ </span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">№ ${number}</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">г. (<u>вписать нужное</u>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${date1}</span></strong></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;">
<strong><span style="font-size: 9.0pt; line-height: 106%;">РГП на ПХВ &laquo;Национальный центр экспертизы&raquo; КСЭК МЗ РК, </span></strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;именуемое в дальнейшем &laquo;Исполнитель&raquo;, в лице <strong>${position} филиала ${filial}</strong> ${supervisor} действующего на основании ${proxy?.proxy} №${proxy?.proxyNumber} от ${proxy?.proxyDate}. с одной стороны, и ${client} именуемое в дальнейшем &laquo;Заказчик&raquo;, в лице <strong>должность ${supervisorName}</strong> действующего на основании <strong><u>(наименование документа)</u></strong>, с другой стороны, при совместном упоминании именуемые Стороны, а по отдельности Сторона или как указано выше, заключили настоящий договор (далее - Договор) о нижеследующем:</span></p>
<p style="text-align: justify; text-indent: 22.95pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">1. Предмет договора</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1.1 Исполнитель обязуется оказать Заказчику услуги согласно приложению к настоящему Договору (далее &ndash; Услуги), а Заказчик принять и оплатить Услуги на условиях настоящего Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1.2 Перечисленные ниже документы и условия, оговоренные в них, образуют данный Договор и считаются его неотъемлемой частью, а именно:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1) настоящий Договор;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2) перечень закупаемых услуг (Приложение).</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">2. Стоимость Договора и условия оплаты</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.1 Общая сумма Договора составляет <strong>${price}</strong></span><strong> </strong><span style="font-size: 9.0pt; line-height: 106%;">(_____________) <strong>тенге </strong>и включает все расходы, связанные с выполнением услуг, а также все налоги и сборы, предусмотренные законодательством Республики Казахстан. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.2 Сумма оплачивается путем перечисления на расчетный счет Исполнителя после фактического оказания услуг, в соответствии с приложением к настоящему Договору, и в течении 30 (тридцати) календарных дней со дня подписания Сторонами акта оказанных Услуг. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Форма акта оказанных Услуг предварительно согласовывается с Заказчиком.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Заказчик имеет право производить авансовый платеж. Оставшаяся сумма оплачивается Заказчиком путем перечисления денежных средств на расчетный счет Исполнителя не позднее 30 (тридцати) календарных дней с даты подписания Сторонами акта оказанных услуг, с учетом удержания ранее оплаченного аванса.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.3 Объем оказываемых Услуг в количественном и стоимостном выражении оговорен в Приложении к Договору.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.4 Необходимые документы, предшествующие оплате:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1) подписанный Договор;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2) акт выполненных Работ;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3) счет к оплате, предоставленный Исполнителем Заказчику. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">2.5.Выезд специалистов для оказания услуг осуществляется на автотранспорте Исполнителя, согласно Прейскуранту цен Исполнителя. В случае выезда на автотранспорте Заказчика, выезд осуществляется на безвозмездной основе. Если выезд работников Исполнителя осуществляется на расстояние более 60 км в одну сторону, то Заказчик оплачивает командировочные расходы согласно Прейскуранту цен Исполнителя.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">3. Обязательства Сторон</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.1 Исполнитель обязуется:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">1) обеспечить полное и надлежащее исполнение взятых на себя обязательств по Договору;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) по первому требованию Заказчика предоставлять информацию о ходе исполнения обязательств по Договору;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3) выдать Заказчику протоколы исследований после получения полной оплаты за оказанные услуги.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">Примечание: в случае оказания дезинфекционных услуг</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">, норму подпункта 3 пункта 3.1 следует удалить из текста договора</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3.2 Заказчик обязуется:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">1) обеспечить доступ специалистов </span><span style="font-size: 9.0pt; line-height: 106%;">Исполнителя<span style="color: black;"> для оказания Услуг;</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) в соответствии с условиями Договора принимать и оплачивать Услуги, оказанные </span><span style="font-size: 9.0pt; line-height: 106%;">Исполнителем<span style="color: black;"> по Договору;</span></span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3) не использовать данный договор для выполнения обязательств перед третьими лицами (субподряд);</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">3.3 Стороны обязуются заключить дополнительное соглашение при неудовлетворительных результатах лабораторных исследований продукции, повторно предоставить на исследование удвоенное количество образцов для проведения дополнительного контроля производства по ходу технологического процесса, сырья, полуфабрикатов, вспомогательных материалов, воды, санитарно-гигиенического состояния всех рабочих помещений и проводятся соответствующие санитарно-гигиенические и противоэпидемические мероприятия. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">Примечание: в случае оказания дезинфекционных услуг</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">, норму пункта 3.3 следует удалить из текста договора</span></u></em><em><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.4 </span><span style="font-size: 9.0pt; line-height: 106%;">Стороны, договора обязаны соблюдать нормы антикоррупционного законодательства Республики Казахстан и международных соглашений, ратифицированных Республикой Казахстан в области противодействия коррупции.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.5 Стороны, при исполнении принятых обязательств по договору обязаны соблюдать нормы деловой этики, добропорядочности, а также основные принципы добросовестной конкуренции.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">3.6 Стороны, при заключении договора, а также в процессе исполнения принятых обязательств по договору обязаны раскрывать сведений о реальном или потенциальном конфликте интересов.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><u><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></u></em></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">4. Проверка Услуг на соответствие технической </span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">спецификации</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.</span><span style="font-size: 9.0pt; line-height: 106%;">1</span><span style="font-size: 9.0pt; line-height: 106%;"> Услуги, оказываемые в рамках данного Договора, должны соответствовать или быть выше стандартов, указанных в технической спецификации.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.</span><span style="font-size: 9.0pt; line-height: 106%;">2</span><span style="font-size: 9.0pt; line-height: 106%;"> Если результаты оказанных Услуг будут признаны не соответствующими требованиям технической спецификации по вине Исполнителя, Исполнитель принимает меры по устранению несоответствий требованиям технической спецификации, без каких-либо дополнительных затрат со стороны Заказчика.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">4.</span><span style="font-size: 9.0pt; line-height: 106%;">3</span><span style="font-size: 9.0pt; line-height: 106%;"> Ни один вышеуказанный пункт не освобождает Исполнителя от других обязательств по Договору. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; color: black;">5. Оказание Услуг и документация</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.1 Оказание Услуг Исполнителем осуществляется в соответствии с Приложением к Договору, являющемся неотъемлемой частью настоящего Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">5.2 Для расчетов за оказанные Услуги Исполнитель должен предоставить следующие документы:</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">1) акты оказанных Услуг в двух экземплярах;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">2) счет-фактура с указанием суммы оказанных Услуг.</span><strong><span style="font-size: 9.0pt; line-height: 106%;"> </span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">5.3. Сроки оказания услуг &ndash; </span><span style="font-size: 9.0pt; line-height: 106%;">c</span><span style="font-size: 9.0pt; line-height: 106%;"> </span><span style="font-size: 9.0pt; line-height: 106%;">&laquo;___&raquo; ___________ 202__г</span><span style="font-size: 9.0pt; line-height: 106%;">.</span><span style="font-size: 9.0pt; line-height: 106%;"> по &laquo;___&raquo; ___________ 202__г.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">5.4. Место оказания услуг: согласно приложению к Договору.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">5.5 Услуга считается оказанной при условии полной сдачи Исполнителем услуги Заказчику в точном соответствии требованиям, указанным в приложениях к настоящему Договору.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">6. Гарантия</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.1 Исполнитель гарантирует обеспечение бесперебойного, качественного и своевременного оказания Услуг Заказчику.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.2 Исполнитель гарантирует безвозмездное исправление ошибок, недоработок и других несоответствий технический спецификации (Приложение к Договору).</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.3 Заказчик обязан оперативно уведомить Исполнителя в письменном виде обо всех претензиях, связанных с данной гарантией, после чего Исполнитель должен принять меры по устранению недостатков.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">6.4 С подписанием Договора Заказчик безусловно гарантирует, что ни он, ни его участники/акционеры и конечные владельцы не являются связанной стороной по отношению к Исполнителю, а также отсутствует конфликт интересов между ними в отношении предмета Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">7. Условия конфиденциальности</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.1 По взаимному согласию сторон в рамках настоящего Договора конфиденциальной признается любая информация, ставшая известная сторонам в ходе выполнения настоящего Договора, в том числе, но не</span> <span style="font-size: 9.0pt; line-height: 106%;">ограничиваясь, полученные результаты, учредительные документы, разрешительные документы Исполнителя.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.2 Каждая из сторон обязана обеспечить защиту конфиденциальной информации, ставшей доступной ей в рамках настоящего Договора, от несанкционированного использования, распространения или опубликования.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.3 Стороны согласились не раскрывать или разглашать прямо или косвенно в какой-либо форме или каким-либо средствами конфиденциальную информацию или ее часть третьей стороне, без предварительного письменного одобрения второй Стороны, за исключением случаев предусмотренных законодательством Республики Казахстан.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.4 Условия конфиденциальности вступают в силу с момента подписания настоящего Договора и действуют в течение 3 (трех) лет после прекращения срока действия Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.5 Стороны согласились, что каждая Сторона имеет право огласить или дать доступ ко всей и/или части конфиденциальной информации своим работникам в той мере, в которой им необходима данная информация в связи с осуществлением их служебных обязанностей.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.6 Стороны согласились, что расторгнутый договор не имеет юридической силы с момента подписания Соглашения о расторжении, что является неотъемлемой частью Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">7.7 В случае предъявления Договора Заказчиком третьей стороне, утратившего силу на основаниях, предусмотренных настоящим Договором, заказчик несет ответственность в соответствии с разделом 8 Договора.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">8. Ответственность сторон</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.1 <span style="color: black;">В случае нарушения Заказчиком подпункта 3) пункта 3.2, пункта 6.4 и раздела 7 настоящего </span></span><span style="font-size: 9.0pt; line-height: 106%; color: black;">Договора</span><span style="font-size: 9.0pt; line-height: 106%; color: black;">, Исполнитель вправе расторгнуть настоящий договор в одностороннем порядке, а Заказчик обязуется оплатить штраф в размере 50 % от общей суммы договора и возместить все убытки Исполнителя.</span><span style="font-size: 9.0pt; line-height: 106%; color: black;"> В случае нарушения Заказчиком требований пункта 7.7 Договора, Заказчик </span><span style="font-size: 9.0pt; line-height: 106%; color: black;">&nbsp;обязуется оплатить штраф в размере 50 % от общей суммы договора и возместить все убытки Исполнителя.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.2 В случае нарушения установленных Договором сроков оказания Услуг со стороны Исполнителя, Заказчик вправе прекратить исполнение своих обязательств по Договору, направив письменное уведомление о неисполнении обязательств по настоящему договору, оплатив Исполнителю фактически оказанные услуги.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.3 За исключением форс-мажорных условий, если Исполнитель не может предоставить Услуги в сроки, предусмотренные Договором, Заказчик без ущерба другим своим правам в рамках Договора взыскивает неустойку (штраф, пеню) в размере 0,1 (ноль целых один) % от суммы договора за каждый день просрочки, но не более 10% от общей суммы Договора, в случае полного неисполнения поставщиком обязательства за каждый день просрочки либо взыскивает неустойку (штраф, пеню) в размере 0,1 (ноль целых один) % к сумме ненадлежаще исполненного обязательства за каждый день просрочки, но не более 10% от общей суммы Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.4 Выплата неустойки не освобождает Исполнителя от исполнения своих обязательств по Договору.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.5 В случае нарушения пункта 2.2 настоящего договора, Заказчик оплачивает неустойку (штраф, пеню) в размере 0,1 (ноль целых один) %&nbsp; от суммы договора за каждый день просрочки, но не более 10% от общей суммы Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.6 Если любое изменение ведет к уменьшению или увеличению стоимости, или сроков, необходимых Исполнителю для оказания Услуг по Договору, то сумма Договора или срок оказания Услуг, или и то и другое соответствующим образом корректируется, а к Договору составляется Дополнительное соглашение с внесенными поправками. Все запросы Исполнителя на проведение корректировки должны быть предъявлены в течение 5 (пяти) рабочих дней со дня получения Исполнителем распоряжения об изменениях от Заказчика.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.7 Исполнитель ни полностью, ни частично не должен передавать кому-либо свои обязательства по настоящему Договору без предварительного письменного согласия Заказчика.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">8.8 Исполнитель должен предоставить Заказчику копии всех субподрядных договоров, заключенных в рамках данного Договора. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">9. Срок действия и условия </span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">расторжения договора</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.1 Договор вступает в силу со дня подписания и действует по ${date2}</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.2 Заказчик может в любое время в одностороннем порядке отказаться от исполнения условий Договора, направив Исполнителю соответствующее письменное уведомление, если Исполнитель становится банкротом или неплатежеспособным. В этом случае отказ от исполнения условий Договора осуществляется немедленно, и Заказчик не несет никакой финансовой обязанности по отношению к Исполнителю при условии, если отказ от исполнения условий Договора не наносит ущерба или не затрагивает каких-либо прав на совершение действий или применение санкций, которые были или будут впоследствии предъявлены Заказчику.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.3 Исполнитель может в любое время </span><span style="font-size: 9.0pt; line-height: 106%;">в одностороннем порядке отказаться от исполнения условий Договора, направив </span><span style="font-size: 9.0pt; line-height: 106%;">Заказчику</span><span style="font-size: 9.0pt; line-height: 106%;"> соответствующее письменное уведомление, </span><span style="font-size: 9.0pt; line-height: 106%;">ввиду дальнейшей нецелесообразности оказания Услуг</span><span style="font-size: 9.0pt; line-height: 106%;">.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.</span><span style="font-size: 9.0pt; line-height: 106%;">4 </span><span style="font-size: 9.0pt; line-height: 106%;">Когда Договор расторгается на основании п. 9.2 настоящего Договора, Исполнитель имеет право требовать оплату за фактические затраты, связанные с расторжением по Договору, на день расторжения.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.</span><span style="font-size: 9.0pt; line-height: 106%;">5</span><span style="font-size: 9.0pt; line-height: 106%;"> Без ущерба каким-либо другим санкциям за нарушение условий Договора Заказчик может расторгнуть настоящий Договор полностью или частично, направив Исполнителю письменное уведомление о невыполнении обязательств если Исполнитель не может оказать Услуги в сроки, предусмотренные Договором, или в течение периода продления настоящего Договора, предоставленного Заказчиком.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.</span><span style="font-size: 9.0pt; line-height: 106%;">6</span><span style="font-size: 9.0pt; line-height: 106%;"> Договор может быть расторгнут по соглашению сторон. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">9.</span><span style="font-size: 9.0pt; line-height: 106%;">7</span><span style="font-size: 9.0pt; line-height: 106%;"> В вышеперечисленных случаях, представитель Исполнителя наносит запись на каждом листе Договора</span><span style="font-size: 9.0pt; line-height: 106%;"> Заказчика</span><span style="font-size: 9.0pt; line-height: 106%;">, подтверждающую расторжение Договора по каким-либо причинам, предусмотренным настоящим разделом.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">10. <span style="color: black;">Форс-мажор</span></span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">10.1 Стороны не несут ответственность за неисполнение условий Договора, если оно явилось результатом форс-мажорных обстоятельств.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">10.2 Стороны Договора не несут ответственность за выплату неустоек или расторжение Договора в силу неисполнения его условий, если задержка с исполнением Договора является результатом форс-мажорных обстоятельств.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">10.3 Для целей Договора &laquo;форс-мажор&raquo; означает событие, неподвластное контролю Сторон, и имеющее непредвиденный характер. Такие события могут включать, но не исключительно: военные действия, природные или стихийные бедствия и другие.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%; color: black;">10.4 При возникновении форс-мажорных обстоятельств Исполнитель должен незамедлительно направить Заказчику письменное уведомление о таких обстоятельствах и их причинах. Если от Заказчика не поступает иных письменных инструкций, Заказчик продолжает выполнять свои обязательства по Договору, насколько это целесообразно, и ведет поиск альтернативных способов выполнения Договора, не зависящих от форс- мажорных обстоятельств.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">11. Решение спорных вопросов</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">11.1 Заказчик и Исполнитель должны прилагать все усилия к тому, чтобы разрешать в процессе прямых переговоров все разногласия или споры, возникающие между ними по Договору или в связи с ним</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">11.2 Если в течение 21 (двадцати одного) календарного дня после начала таких переговоров Заказчик и Исполнитель не могут разрешить спор по Договору, любая из сторон может потребовать решения этого вопроса в соответствии с законодательством Республики Казахстан.</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">12. Уведомление</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">12.1 Любое уведомление, которое одна сторона направляет другой стороне в соответствии с Договором, высылается в виде письма, телеграммы, телекса, факса</span><span style="font-size: 9.0pt; line-height: 106%;"> или посредсвом электронной почты</span><span style="font-size: 9.0pt; line-height: 106%;"> с последующим предоставлением оригинала.</span><span style="font-size: 9.0pt; line-height: 106%;"> Электронные адреса Сторон указываются в разделе 14 настоящего Договора.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">12.2 Уведомление вступает в силу после доставки или в указанный день вступления в силу (если указано в уведомлении) в зависимости от того, какая из этих дат наступит позднее.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">13. Прочие условия</span></strong></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.1 Любые изменения и дополнения к Договору действительны лишь при условии, если они совершены в письменной форме и подписаны уполномоченными на то представителями Сторон и скреплены их печатями.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.2</span><span style="font-size: 9.0pt; line-height: 106%;"> Дополнительные соглашения оформляются исключительно при предъявлении Заказчиком подлинника Договора;</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.</span><span style="font-size: 9.0pt; line-height: 106%;">3</span><span style="font-size: 9.0pt; line-height: 106%;"> Передача обязанностей одной из Сторон по Договору допускается только с письменного согласия другой Стороны.</span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.</span><span style="font-size: 9.0pt; line-height: 106%;">4</span><span style="font-size: 9.0pt; line-height: 106%;"> Договор составлен в двух экземплярах на казахском и русском языках, имеющих одинаковую юридическую силу, по одному экземпляру для каждой из Сторон. </span></p>
<p style="text-align: justify; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">13.</span><span style="font-size: 9.0pt; line-height: 106%;">5</span><span style="font-size: 9.0pt; line-height: 106%;"> В части, неурегулированной Договором, Стороны руководствуются законодательством Республики Казахстан.<strong><span style="text-transform: uppercase;"> </span></strong></span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; text-transform: uppercase;">&nbsp;</span></strong></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%; text-transform: uppercase;">14. </span></strong><strong><span style="font-size: 9.0pt; line-height: 106%;">Реквизиты сторон</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">Исполнитель</span></strong></p>
<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">${filial}</span></p>

<p style="margin: 0cm -33.3pt 0.0001pt 0cm; line-height: 106%; break-after: avoid; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Адрес: ${filialDate?.addressLine}</span></p>
<p style="line-height: 106%; break-after: avoid; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БИН ${filialDate?.bin} </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">ИИК KZ${filialDate?.bankAccountNumber}</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">АО </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">БИК ${filialDate?.bankBik}</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">КБе ${filialDate?.beneficiaryCode}</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">Тел ${filialDate?.phoneNumber} </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">e</span><span style="font-size: 9.0pt; line-height: 106%;">-</span><span style="font-size: 9.0pt; line-height: 106%;">mail</span><span style="font-size: 9.0pt; line-height: 106%;">:___________________</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">Директор&nbsp;&nbsp;&nbsp; </span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9.0pt; line-height: 106%;">${supervisor} <u>&nbsp;Ф</u></span></strong><strong><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></strong><strong><u><span style="font-size: 9.0pt; line-height: 106%;">И</span></u></strong><strong><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></strong><strong><u><span style="font-size: 9.0pt; line-height: 106%;">О</span></u></strong><strong><u><span style="font-size: 9.0pt; line-height: 106%;">.</span></u></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">М.П.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp; </span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9.0pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9pt; line-height: 106%;">Заказчик</span></strong><span style="font-size: 9pt; line-height: 106%;"> </span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">${client}</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">Адрес: ${addressLine}</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">БИН/ИИН ${bin}/${inn} </span><span style="font-size: 9pt; line-height: 106%;">${bank}</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">ИИК ${bankAccountNumber}</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">БИК ${bankBik}</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">Кбе</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">&nbsp;</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">Тел. </span><span style="font-size: 11pt; line-height: 106%;">&nbsp;${phoneNumber}</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">e</span><span style="font-size: 9pt; line-height: 106%;">-</span><span style="font-size: 9pt; line-height: 106%;">mail</span><span style="font-size: 9pt; line-height: 106%;">:___________________</span></p>
<p style="text-indent: -3.7pt; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">&nbsp;</span></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9pt; line-height: 106%;">Директор</span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><strong><span style="font-size: 9pt; line-height: 106%;">${supervisorName} </span></strong></p>
<p style="line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><span style="font-size: 9pt; line-height: 106%;">М.П.</span></p>
<p style="text-align: center; line-height: 106%; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;">&nbsp;</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p style="margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt; color: black;">&nbsp;</span></em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>Приложение</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>&nbsp; к Договору</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>№${number} </em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;"><em>&nbsp;&nbsp;&nbsp; от ${date1}</em></p>
<p style="text-align: right; margin: 0cm 0cm 0.0001pt; font-size: 12pt; font-family: 'Times New Roman', serif;">&nbsp;</p>
<p style="text-align: center;"><strong>Перечень закупаемых услуг</strong></p>
<table style="border-collapse: collapse; width: 99.8328%; border-style: solid;" border="1">
<tbody>
<tr>
<td style="width: 12.5105%;" width="42">
<p><strong>№</strong></p>
</td>
<td style="width: 12.5105%;" width="68">
<p><strong>Вид услуги</strong></p>
</td>
<td style="width: 12.5105%;" width="113">
<p><strong>Наименование услуги/анализа</strong></p>
</td>
<td style="width: 12.5105%;" width="72">
<p><strong>Единица измерения</strong></p>
</td>
<td style="width: 12.5105%;" width="68">
<p><strong>График</strong></p>
</td>
<td style="width: 12.5945%;" width="72">
<p><strong>Количество </strong></p>
</td>
<td style="width: 12.5105%;" width="49">
<p><strong>Цена</strong></p>
</td>
<td style="width: 12.3426%;" width="64">
<p><strong>Сумма</strong></p>
</td>
</tr>
${specification && specification.map((el, idx) => {
    return `<tr>
                    <td style="width: 11.1079%;">
                        ${idx + 1}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.service?.category?.name || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.service?.name || el.group?.name || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.unit?.name ? el.unit?.name : el.unit || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el?.schedule?.name || ''}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.quantity || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.price || 'Не задано'}
                    </td>
                    <td style="width: 11.1079%;">
                        ${((el.quantity ? el.quantity : 1) * el.price).toString()}
                    </td>
                </tr>`
})}
</tbody>
</table>
<table style="border-collapse: collapse; width: 99.1639%; border-style: hidden;" border="0">
<tbody>
<tr>
<td style="width: 51.4786%;">
<ol start="8">
<li><strong>8. Тараптардың реквизиттері</strong></li>
</ol>
<p><strong>Орындаушы</strong></p>
<p>ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК атынан ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК _________________________ филиалы</p>
<p>Мекенжайы: ${filialDate?.addressLine}</p>
<p>БСН ${filialDate?.bin}</p>
<p>ЖСК KZ${filialDate?.bankAccountNumber}</p>
<p>&nbsp;</p>
<p>БСК ${filialDate?.bankBik}</p>
<p>КБе ${filialDate?.beneficiaryCode}</p>
<p>Тел ${filialDate?.phoneNumber}</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>${supervisor} Т.А.Ә.</p>
<p>&nbsp;М.О.</p>
<p><strong>Тапсырыс беруші </strong></p>
<p>________________</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ${inn}/${bin}</p>
<p>ЖСК KZ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БСК ${bankBik}</p>
<p>КБе _______________</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Қолы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; М.О.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</td>
<td style="width: 48.52%;">
<ol start="8">
<li><strong>8. Реквизиты сторон</strong></li>
</ol>
<p><strong>Исполнитель</strong></p>
<p>${filial}</p>
<p>Адрес: ${filialDate?.addressLine}</p>
<p>БИН ${filialDate?.bin}</p>
<p>ИИК KZ${filialDate?.bankAccountNumber}</p>
<p>&nbsp;</p>
<p>БИК ${filialDate?.bankBik}</p>
<p>КБе ${filialDate?.beneficiaryCode}</p>
<p>Тел ${filialDate?.phoneNumber}</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>${supervisor} Ф.И.О.</p>
<p>М.П.</p>
<p><strong>Заказчик </strong></p>
<p>${client}</p>
<p>Адрес: ${addressLine}</p>
<p>ИИН/БИН ${inn ? inn : bin}</p>
<p>ИИК KZ ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БИК ${bankBik}</p>
<p>КБе ____</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>${supervisorName} Ф.И.О.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Подпись</p>
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>`


export const initialValueVariantPreviewBasket = ({specification, position, proxy, supervisorName, city, name, number, filial, client, inn, bin, date2, date1, price, phoneNumber, addressLine, supervisor, bank, bankBik, bankAccountNumber}) => `<table style="border-collapse: collapse; width: 100.082%; height: 3352px;" border="1">
<tbody>
<tr style="height: 3513.31px;">
<td style="width: 49.9346%; height: 3513.31px;">
<p>№ ________</p>
<p><strong>ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫ</strong></p>
<p><strong>&nbsp;</strong></p>
<p style="text-align: left;"><strong>(керегін жазу) қ.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 202__ж. &laquo;___&raquo; __________</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Бұдан әрі &laquo;Орындаушы&raquo; деп аталатын, <strong>ҚР ДСМ СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong>, __ 202__ ж. __ Бас сенімхат негізінде әрекет ететін <strong>ҚР ДСМ СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong> филиал ${position} <strong><u>(филиалдың атауы, аты-жөні)</u> </strong>бір тараптан және және &nbsp;&nbsp;_____________________________ (жеке тұлға, Т.А.Ә. ), бұдан әрі &laquo;Тапсырыс беруші&raquo; деп аталатын, басқа жағынан, бірлесіп ескергенде &laquo;Тараптар&raquo; , жеке айтқанда &laquo;Тарап&raquo;&nbsp; немесе&nbsp; жоғарыда айтылғандай, осы Шартты (бұдан әрі - шарт) жасасты:</p>
<ol>
<li><strong> Шарттың мәні</strong></li>
</ol>
<p>1.1 Орындаушы Тапсырыс берушіге осы Шарттың қосымшасына (бұдан әрі - Қызметтер) сәйкес қызмет көрсетуге міндеттенеді және Тапсырыс беруші Қызметтерді осы Шарттың талаптарына сәйкес қабылдауға және төлеуге міндеттенеді.</p>
<p>1.2 Төменде келтірілген құжаттар мен онда айтылған шарттар осы Шартты құрайды және оның ажырамас бөлігі болып табылады, атап айтқанда: 1) осы Шарт; 2) сатып алынатын қызметтер тізімі (Қосымша).</p>
<ol start="2">
<li><strong> Шарттың құны және төлем шарттары</strong></li>
</ol>
<p>2.1 Шарттың жалпы сомасы Қосымшада көрсетілген және қызметтерді жүзеге асырумен байланысты барлық шығыстарды, сондай-ақ Қазақстан Республикасының заңнамасында көзделген барлық салықтар мен алымдарды қамтиды.</p>
<p>2.2 Тапсырыс беруші Шарт күшіне енген күннен бастап 3 (үш) жұмыс күн ішінде Орындаушының есеп айырысу шотына/ кассасына&nbsp; 100% көлемде аванстық төлем жасайды.</p>
<p>2.3 Саны және құны бойынша ұсынылатын Қызметтер сомасы осы Шарттың 1-қосымшасында көрсетілген.</p>
<p><em><u>Ескерту: Келесі нұсқаның бірін таңдаңыз:</u></em></p>
<p><em>□ Қызметтерді көрсету үлгілері Тапсырыс берушімен дербес қамтамасыз етіледі.&nbsp;&nbsp; </em></p>
<p><em>□ Қызмет көрсету үшін мамандардың жол жүруі Тапсырыс берушінің көлік құралдарымен жүзеге асырылады.</em></p>
<p><em>□ Қызметтерді көрсету үшін мамандардың жол жүруі Орындаушының бағаларына сәйкес Орындаушының автокөлігімен жүзеге асырылады.</em></p>
<ol start="3">
<li><strong> Тараптардың міндеттері</strong></li>
</ol>
<p>3.1 Орындаушы міндетіне алады:</p>
<p>1) Шарт бойынша өзіне алған міндеттемелердің толық және тиесілі орындалуын қамтамасыз етуге, сондай-ақ Тапсырыс берушінің талабы бойынша Шарт бойынша міндеттемелерді орындау барысы туралы ақпарат беруге;</p>
<p>2) Қызмет көрсету аяқталғаннан кейін және осы Шарттың 2.2-тармағының орындалуына қарай хаттамалар беруге;&nbsp;</p>
<p>3.2 Тапсырыс беруші міндеттеледі:</p>
<p>1) Қызметтерді көрсету үшін Орындаушының мамандарына қол объектіге жетімділікті қамтамасыз ету;</p>
<p>2) Шарттың талаптарына сәйкес Шарт бойынша Орындаушымен көрсетілетін қызметтерді қабылдайды және төлейді.</p>
<p>3.3 Шарттың тараптары Қазақстан Республикасының Сыбайлас жемқорлыққа қарсы заңнамасының және Қазақстан Республикасы ратификациялаған сыбайлас жемқорлыққа қарсы іс-қимыл саласындағы халықаралық келісімдердің нормаларын сақтауға міндетті.</p>
<p>3.4 Тараптар шарт бойынша қабылданған міндеттемелерді орындау кезінде іскерлік әдеп, парасаттылық нормаларын, сондай-</p>
<p>ақ адал бәсекелестіктің негізгі қағидаттарын сақтауға міндетті.</p>
<p>3.5 Тараптар шарт жасасу кезінде, сондай-ақ шарт бойынша қабылданған міндеттемелерді орындау процесінде мүдделердің нақты немесе ықтимал қақтығысы туралы мәліметтерді ашуға міндетті.</p>
<ol start="4">
<li><strong> Тараптардың жауапкершілігі</strong></li>
</ol>
<p>4.1 Тапсырыс беруші осы шарттың 2.2. т. бұзған жағдайда, Орындаушы осы шартты біржақты тәртіпте бұзуға құқылы, ал Тапсырыс беруші Орындаушының барлық шығындарын өтеуге міндеттенеді</p>
<p>4.2 Орындаушы тарапынан осы Шартпен көзделген Қызмет көрсету мерзімдері&nbsp; бұзылған жағдайда, Тапсырыс беруші Шарт бойынша өз міндеттемелерін орындауды Орындаушыға нақты көрсетілетін қызметтерді төлей отырып, осы Шарт бойынша міндеттемелерін орындамағаны туралы хабарлама жіберу арқылы тоқтатуға құқылы.</p>
<p>4.3 Егер қандай да бір өзгеріс Орындаушының Шарт бойынша Қызметтерді көрсетуге қажетті шығындардың немесе мерзімінің азаюына немесе көбеюіне әкелсе, онда Шарттың сомасы немесе Қызмет көрсету мерзімі немесе екеуі де тиісті түрде түзетіледі және Шартқа өзгертулер енгізілген қосымша келісім жасалады.</p>
<ol start="5">
<li><strong> Шарттың жарамдылық мерзімі және тоқтату негіздері</strong></li>
</ol>
<p>5.1 Шарт қол қойылған күнінен бастап күшіне енеді және 202__ жылғы &laquo;__&raquo; _______&nbsp; дейін күшінде болады.</p>
<p>5.2 Шарт тараптардың келісімі бойынша тоқтатылуы мүмкін.</p>
<p>5.3. Тараптар форс-мажор жағдайының нәтижесі болған жағдайда, Шарттың талаптарын орындамағаны үшін жауап бермейді.</p>
<ol start="6">
<li><strong> Басқа жағдайлар</strong></li>
</ol>
<p>6.1 Шартқа кез келген өзгерістер мен толықтырулар тек олар жазбаша нұсқада және Тараптардың осыған өкілетті өкілдері қол қойған және олардың мөрлерімен бекітілген жағдайда ғана шынайы деп танылады.</p>
<p>6.2 Тапсырыс беруші мен Орындаушы Шарт бойынша немесе онымен байланысты туындаған барлық келіспеушіліктерді немесе келіспеушіліктерді тікелей келіссөздер түрінде шешу үшін барлық күш-жігерін салуы керек.</p>
<p>6.3 Шартта көдделмеген мәселелер бойынша Тараптар Қазақстан Республикасының заңнамасын басшылыққа алады.</p>
<ol start="7">
<li><strong> Тараптардың реквизиттері</strong></li>
</ol>
<p><strong>Орындаушы</strong></p>
<p>ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК атынан ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК __________________________________________ филиалы</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ____________________</p>
<p>ЖСК KZ_________________________</p>
<p>&nbsp;</p>
<p>БСК ______________</p>
<p>КБе 16</p>
<p>Тел ___________________</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;М.О.</p>
<p>&nbsp;</p>
<p><strong>Тапсырыс беруші </strong></p>
<p>________________</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ____________________</p>
<p>ЖСК KZ_________________________</p>
<p>Банк ____________________</p>
<p>БСК ______________</p>
<p>КБе _______________</p>
<p>Тел ___________________</p>
<p>&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Қолы&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p>М.О.</p>
</td>
<td style="width: 50.0652%; height: 3513.31px;">
<p><strong>${name.toUpperCase()} </strong></p>
<p><strong>№ ${number}</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>г. <u></u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${date1}</strong></p>
<p>&nbsp;</p>
<p><strong>РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК</strong>, именуемое в дальнейшем «Исполнитель», в лице <strong>директора филиала РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК</strong>, действующего на основании Генеральной доверенности №_____ от «__» ________ 202__ г. с одной стороны, и и ${client}, именуемое в дальнейшем &laquo;Заказчик&raquo;, с другой стороны, при совместном упоминании именуемые &laquo;Стороны&raquo;, а по отдельности &laquo;Сторона&raquo; или как указано выше, заключили настоящий договор (далее - Договор) о нижеследующем:</p>
<ol start="1">
<li><strong> Предмет договора</strong></li>
</ol>
<p>1.1 Исполнитель обязуется оказать Заказчику услуги согласно приложению к настоящему Договору (далее &ndash; Услуги), а Заказчик оплатить и принять Услуги на условиях настоящего Договора.</p>
<p>1.2 Перечисленные ниже документы и условия, оговоренные в них, образуют данный Договор и считаются его неотъемлемой частью, а именно: 1) настоящий Договор, 2) перечень закупаемых услуг (Приложение).</p>
<ol start="2">
<li><strong> Стоимость Договора и условия оплаты</strong></li>
</ol>
<p>2.1 Общая сумма Договора указана в Приложении к настоящему Договору и включает все расходы, связанные с выполнением услуг, а также все налоги и сборы, предусмотренные законодательством Республики Казахстан.</p>
<p>2.2 Заказчик в течение 3 (трех) рабочих дней со дня вступления Договора в силу, производит на расчетный счет/кассу Исполнителя авансовый платеж в размере 100%.</p>
<p>2.3 Объем оказываемых Услуг в количественном и стоимостном выражении оговорен в Приложении к Договору.</p>
<p><em><u>Примечание: Выберите один из следующих вариантов:</u></em></p>
<ul class="tox-checklist">
<li class="" style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">Пробы для оказания Услуг предоставляются Заказчиком самостоятельно. </span></em></li>
<li class="" style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">&nbsp;Выезд специалистов для оказания услуг осуществляется на автотранспорте Заказчика.</span></em></li>
<li style="text-align: justify; font-size: 12pt; font-family: 'Times New Roman', serif;"><em><span style="font-size: 9.0pt;">&nbsp;Выезд специалистов для оказания услуг осуществляется на автотранспорте Исполнителя, согласно Прейскуранту цен Исполнителя.</span></em></li>
</ul>
<ol start="3">
<li><strong> Обязательства Сторон</strong></li>
</ol>
<p>3.1 Исполнитель обязуется:</p>
<p>1) обеспечить полное и надлежащее исполнение взятых на себя обязательств по Договору, а также по требованию Заказчика предоставлять информацию о ходе исполнения обязательств по Договору;</p>
<p>2) выдать протоколы результатов по завершении оказания услуг и при условии исполнения Заказчиком п. 2.2 настоящего Договора;&nbsp;</p>
<p>3.2 Заказчик обязуется:</p>
<p>1) обеспечить доступ к объекту специалистам Исполнителя для оказания Услуг;</p>
<p>2) в соответствии с условиями Договора принимать и оплачивать Услуги, оказанные Исполнителем по Договору;</p>
<p>3.3 Стороны договора обязаны соблюдать нормы антикоррупционного законодательства Республики Казахстан и международных соглашений, ратифицированных Республикой Казахстан в области противодействия коррупции.</p>
<p>3.4 Стороны, при исполнении принятых обязательств по договору обязаны соблюдать нормы деловой этики, добропорядочности, а также основные принципы добросовестной конкуренции.</p>
<p>3.5 Стороны, при заключении договора, а также в процессе исполнения принятых обязательств по договору обязаны раскрывать сведений о реальном или потенциальном конфликте интересов.</p>
<ol start="4">
<li><strong>4. Ответственность сторон</strong></li>
</ol>
<p>4.1 В случае нарушения Заказчиком п. 2.2. настоящего договора, Исполнитель вправе расторгнуть настоящий договор в одностороннем порядке, а Заказчик обязуется возместить все убытки Исполнителя.</p>
<p>4.2 В случае нарушения установленных Договором сроков оказания Услуг со стороны Исполнителя, Заказчик вправе прекратить исполнение своих обязательств по Договору, направив уведомление о неисполнении обязательств по настоящему договору, оплатив Исполнителю фактически оказанные услуги.</p>
<p>4.3 Если любое изменение ведет к уменьшению или увеличению стоимости, или сроков, необходимых Исполнителю для оказания Услуг по Договору, то сумма Договора или срок оказания Услуг, или и то и другое соответствующим образом корректируется, а к Договору составляется Дополнительное соглашение с внесенными поправками.</p>
<ol start="5">
<li><strong> Срок действия и условия расторжения договора</strong></li>
</ol>
<p>5.1 Договор вступает в силу со дня подписания и действует по по «__» _______ 202__ года.</p>
<p>5.2 Договор может быть расторгнут по соглашению сторон.</p>
<p>5.3. Стороны не несут ответственность за неисполнение условий Договора, если оно явилось результатом форс-мажорных <br />обстоятельств.</p>
<ol start="6">
<li><strong> Прочие условия</strong></li>
</ol>
<p>6.1 Любые изменения и дополнения к Договору действительны лишь при условии, если они совершены в письменной форме и подписаны Сторонами.</p>
<p>6.2 Заказчик и Исполнитель должны прилагать все усилия к тому, чтобы разрешать в процессе прямых переговоров все разногласия или споры, возникающие между ними по Договору или в связи с ним.</p>
<p>6.3 Неурегулированные вопросы Договором, Стороны руководствуются законодательством Республики Казахстан.</p>
<ol start="7">
<li><strong> Реквизиты сторон</strong></li>
</ol>
<p><strong>Исполнитель</strong></p>
<p>РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК в лице Филиала РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК</p>
<p>Адрес: _____________________________</p>
<p>БИН ____________________</p>
<p>ИИК KZ_________________________</p>
<p>&nbsp;</p>
<p>БИК ______________</p>
<p>КБе 16</p>
<p>Тел ___________________</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>____________________ Ф.И.О.</p>
<p>М.П.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Заказчик </strong></p>
<p>${client}</p>
<p>Адрес: ${addressLine}</p>
<p>ИИН/БИН ${inn ? inn : bin}</p>
<p>ИИК KZ ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БИК ${bankBik}</p>
<p>КБе ____</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>${supervisorName} Ф.И.О.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Подпись</p>
<p>&nbsp; М.П.</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p style="text-align: right;"><em>Приложение</em></p>
<p style="text-align: right;"><em>к Договору</em></p>
<p style="text-align: right;"><em>№${number} </em></p>
<p style="text-align: right;"><em>от ${date1}</em></p>
<p style="text-align: center;"><strong>Перечень закупаемых услуг</strong></p>
<table style="border-collapse: collapse; width: 99.9814%;" border="1">
<tbody>
<tr>
<td style="width: 11.1079%;" width="42">
<p><strong>№</strong></p>
</td>
<td style="width: 11.1079%;" width="113">
<p><strong>Наименование услуги/анализа</strong></p>
</td>
<td style="width: 11.1079%;" width="68">
<p><strong>График</strong></p>
</td>
<td style="width: 11.1729%;" width="72">
<p><strong>Количество </strong></p>
</td>
<td style="width: 11.1079%;" width="49">
<p><strong>Цена</strong></p>
</td>
<td style="width: 11.1079%;" width="64">
<p><strong>Сумма</strong></p>
</td>
</tr>
${specification && specification.map((el, idx) => {
    return `<tr>
                    <td style="width: 11.1079%;">
                        ${idx + 1}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.name}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.schedule}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.qty}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.price}
                    </td>
                    <td style="width: 11.1079%;">
                        ${el.summary}
                    </td>
                </tr>`
})}
</tbody>
</table>
<table style="border-collapse: collapse; width: 99.9521%;" border="1">
<tbody>
<tr>
<td style="width: 47.1765%;">
<ol start="8">
<li><strong>8. Тараптардың реквизиттері</strong></li>
</ol>
<p><strong>Орындаушы</strong></p>
<p>ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК атынан ҚР ДСМ СЭБК-нің &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК _________________________ филиалы</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ____________________</p>
<p>ЖСК KZ_________________________</p>
<p>&nbsp;</p>
<p>БСК ______________</p>
<p>КБе 16</p>
<p>Тел ___________________</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;М.О.</p>
<p><strong>Тапсырыс беруші </strong></p>
<p>________________</p>
<p>Мекенжайы: _____________________________</p>
<p>БСН ____________________</p>
<p>ЖСК KZ_________________________</p>
<p>Банк ____________________</p>
<p>БСК ______________</p>
<p>КБе _______________</p>
<p>Тел ___________________</p>
<p>&nbsp;</p>
<p>____________________ Т.А.Ә.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Қолы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; М.О.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</td>
<td style="width: 52.7581%;">
<ol start="8">
<li><strong>8. Реквизиты сторон</strong></li>
</ol>
<p><strong>Исполнитель</strong></p>
<p>РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК в лице Филиала РГП на ПХВ «Национальный центр экспертизы» КСЭК МЗ РК</p>
<p>по ______________</p>
<p>Адрес: </p>
<p>БИН ____________________</p>
<p>ИИК KZ_________________________</p>
<p>БИК ______________</p>
<p>КБе 16</p>
<p>Тел ___________________</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Директор&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>____________________ Ф.И.О.</p>
<p>М.П.</p>
<p><strong>Заказчик </strong></p>
<p>${client}</p>
<p>Адрес: ${addressLine}</p>
<p>ИИН/БИН ${inn ? inn : bin}</p>
<p>ИИК KZ ${bankAccountNumber}</p>
<p>Банк ${bank}</p>
<p>БИК ${bankBik}</p>
<p>КБе ____</p>
<p>Тел ${phoneNumber}</p>
<p>&nbsp;</p>
<p>${supervisorName} Ф.И.О.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Подпись</p>
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>`


export const additionalContract = ({specification, position, proxy, supervisorName, city = "г. Не задано", name, number, schedules, filial, filialDate, client, clientData, inn = "Не задано", bin = "Не задано", date2, date1, price, phoneNumber, addressLine, supervisor, bank = "Не задано", bankBik = "Не задано", bankAccountNumber = "Не задано"}) => `
<table style="border-collapse: collapse; width: 100.082%; height: 2597.52px;" border="1">
<tbody>
<tr style="height: 2597.52px;">
<td style="width: 49.8607%; height: 2597.52px;">
<p>№ ________</p>
<p>ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫНА</p>
<p>ҚОСЫМША КЕЛІСІМ</p>
<p><strong>&nbsp;</strong></p>
<p><strong>(керегін жазу) қ.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;202__ж. &laquo;___&raquo; __________</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Бұдан әрі &laquo;Орындаушы&raquo; деп аталатын, <strong>ҚР ДСМ </strong><strong>СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong>, __ 202__ ж. №__&nbsp; Бас сенімхат негізінде әрекет ететін <strong>ҚР ДСМ </strong><strong>СЭБК</strong> <strong>&laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</strong> филиал ${position} <strong><u>(филиалдың атауы, аты-жөні)</u> </strong>бір тараптан және бұдан әрі &laquo;Тапсырыс беруші&raquo; деп аталынатын екінші тараптан<strong> (<u>ұйымдық-құқықтық нысан және заңды тұлғаның фирмалық атауы</u>) </strong>&nbsp;<strong>(құжаттың атауы)</strong> негізінде әрекет ететін (аты-жөні, лауазым) атынан, бірлесіп ескергенде &laquo;Тараптар&raquo;, жеке айтқанда &laquo;Тарап&raquo; немесе жоғарыда айтылғандай, 2020 ж. &laquo;___&raquo;_____ № ____ өтелмелі қызмет көрсету шартының (бұдан әрі &ndash; Шарт) негізінде, Шартқа Қосымша келісім (бұдан әрі &ndash; Келісім) жасап, төмендегідей келісімге келді:</p>
<p>&nbsp;</p>
<p><strong>1. Өзгерістерді енгізу</strong></p>
<p>1.1 Келесi өзгерістерді және (немесе) қосымшаларды енгізу:</p>
<p>&nbsp;</p>
<p><strong><em><u>Шарттың</u></em></strong><strong><em><u> сомасын көбейту/азайту жағдайында:</u></em></strong></p>
<p>1) Шарттың барлық мәтінінде, &laquo;_____________&raquo; (____________) сандары мен сөздері <em><u>(шарттың ескі сомасы көрсетіледі)</u></em> &laquo;_____________&raquo; (____________) сандары мен сөздеріне <em><u>(көбейтілген/азайтылған шарттың сомасы көрсетіледі) </u></em>ауыстырылсын.</p>
<p>&nbsp;</p>
<p><strong><em><u>Шарттың мерзімі ұзартылған/қысқартылған жағдайда:</u></em></strong></p>
<p>1) Шарттың 9.1 тармағында, 202__ жылғы &laquo;___&raquo;______ сандары мен сөздері <em><u>(шарттың ескі мерзімі көрсетіледі) </u></em>202__ жылғы &laquo;___&raquo;______ сандары мен сөздеріне ауыстырылсын <em><u>(шарттың ұзартылған /қысқкартылған мерзімі көрсетіледі)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>Қызмет көрсету мерзімі өзгерген жағдайда:</u></em></strong></p>
<p>1) Шарттың 5.3 тармағында, 202__ ж. &laquo;___&raquo; ___________ нан 202__ ж. &laquo;___&raquo; ___________ сандары <em><u>(қызмет көрсетудің ескі мерзімі көрсетіледі) </u></em>202__ ж. &laquo;___&raquo; ___________ нан 202__ ж. &laquo;___&raquo; ___________ сандарына ауыстырылсын <em><u>(қызмет көрсетудің жаңартылған мерзімі көрсетіледі)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>Шарттың қосымшасына өзгерістер енгізілген жағдайда:</u></em></strong></p>
<p>1) Шарттың қосымшасы осы Келісімнің қосымшасына сәйкес жаңа редакцияда жазылсын <em><u>(зерттеулердің/қызметтердің жаңартылған тізімі көрсетілген жаңа қосымша қоса беріледі)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>Тараптардың реквизиттері ауысқан жағдайда:</u></em></strong></p>
<p>1) Шарттың 14 тарауында, __________________________ сандары мен сөздері <em><u>(ескі реквизиттер көрсетіледі)</u></em> __________________________ сандары мен сөздеріне ауыстырылсын <em><u>(жаңа реквизиттер көрсетіледі)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>Шарт бұзылған жағдайда:</u></em></strong></p>
<p>1) Тараптар Шарттың _____ тармағы негізінде осы шартты бұзу туралы келісімге келді <em><u>(Шартты бұзуға негіздеме көрсетіледі)</u></em>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>1.2 Келісім Шарттың ажырамас бөлігі болып табылады.</p>
<p>1.3 Шарттың, Келісім өзгертпейтін бөлігіндегі барлық басқа талаптары күшінде қалады.</p>
<p>1.4 &nbsp;Келісім қол қойылған күнінен бастап күшіне енеді және шарттың қолданылу мерзімі ішінде қолданылады.</p>
<p>1.5 Келісім екі данада, бірдей заңды күші бар, қазақ және орыс тілдерінде жасалды</p>
<p>&nbsp;</p>
<p><strong>2. Тараптардың реквизиттер</strong></p>
<p><strong>Орындаушы</strong></p>
<p>ҚР ДСМ СЭБК &laquo;Ұлттық сараптама орталығы&raquo; ШЖҚ РМК</p>
<p>атынан әрекет ететін ҚР ДСМ СЭБК &laquo;Ұлттық сараптама</p>
<p>орталығы&raquo; ШЖҚ РМК ____________________ филиалы</p>
<p>Мекен-жайы: ________________________________</p>
<p>БСН ______________________</p>
<p>ЖСК KZ_____________________________</p>
<p>АҚ</p>
<p>БСК ___________________</p>
<p>КБе 16</p>
<p>Тел __________________</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;<strong>Директор&nbsp; </strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>____________________ <u>&nbsp;</u></strong>&nbsp;<strong><u>Т.А.Ә.</u></strong></p>
<p>М.О.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Тапсырыс беруші</strong></p>
<p>&laquo;_____________&raquo;</p>
<p>Мекен-жайы: __________________</p>
<p>БСН</p>
<p>Банк</p>
<p>ЖСК</p>
<p>БСК&nbsp;</p>
<p>&nbsp;</p>
<p>Тел. &nbsp;</p>
<p>&nbsp;</p>
<p><strong>Директор</strong><strong>&nbsp;</strong></p>
<p><strong>____________________</strong></p>
<p>М.П.</p>
</td>
<td style="width: 50.0577%; height: 2597.52px;">
<p><strong>ДОПОЛНИТЕЛЬНОЕ СОГЛАШЕНИЕ К <br />ДОГОВОРУ ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ </strong></p>
<p><strong>№ ________</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>${city}<u></u>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${date1}</strong></p>
<p>&nbsp;</p>
<p><strong>${filial}, </strong>именуемое в дальнейшем &laquo;Исполнитель&raquo;, в лице <strong>${position} филиала ${filial} ${supervisor},</strong> действующего на основании Генеральной доверенности ${proxy?.proxy} №${proxy?.proxyNumber} от ${proxy?.proxyDate}. с одной стороны, и <strong>${client},</strong> именуемое в дальнейшем &laquo;Заказчик&raquo;, в лице <strong>(<u>должность, Ф.И.О.</u>),</strong> действующего на основании <strong><u>(наименование документа)</u></strong>, с другой стороны, при совместном упоминании именуемые Стороны, а по отдельности Сторона или как указано выше, на основании договора возмездного оказания услуг № ____ от &laquo;___&raquo;_____202___ г. (далее &ndash; Договора) заключили настоящее дополнительное соглашение (далее &ndash; Соглашение) к Договору и пришли к соглашению о нижеследующем:</p>
<p>&nbsp;</p>
<p><strong>1. Внесение изменений</strong></p>
<p>1.1 Внести следующие изменения и (или) дополнения:</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае увеличения/уменьшения суммы Договора:</u></em></strong></p>
<p>1) По всему тексту договора, цифры и слова &laquo;_____________&raquo; (____________) <em><u>(указывается старая сумма договора)</u></em> заменить цифрами и словами &laquo;_____________&raquo; (____________) <em><u>(указывается сумма договора, с учетом уменьшения/увеличения)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае продления/уменьшения срока действия Договора:</u></em></strong></p>
<p>1) В пункте 9.1 Договора, цифры и слова &laquo;___&raquo;______202__ года <em><u>(указывается старый срок действия договора)</u></em> заменить цифрами и словами &laquo;___&raquo;______202__ года <em><u>(указывается новый срок действия договора)</u></em>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае изменения сроков оказания Услуг:</u></em></strong></p>
<p>1) В пункте 5.3 Договора, цифры &laquo;___&raquo;______202__ г. по с &laquo;___&raquo;______202__ г. <em><u>(указывается старый срок оказания услуг)</u></em> заменить цифрами &nbsp;&laquo;___&raquo;______202__ г. по&nbsp; с &laquo;___&raquo;______202__ г. <em><u>(указывается новый срок оказания услуг)</u></em>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае внесения изменений в приложение Договора:</u></em></strong></p>
<p>1) Приложение договора изложить в новой редакции, согласно приложению, к настоящему Соглашению <em><u>(прилагается новое приложение, с актуальным список исследований/услуг)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае изменения реквизитов сторон:</u></em></strong></p>
<p>1) В разделе 14 Договора, цифры и слова __________________________ <em><u>(указываются старые реквизиты)</u></em> заменить цифрами и словами __________________________<em><u> (указываются новые реквизиты)</u></em>.</p>
<p>&nbsp;</p>
<p><strong><em><u>В случае расторжения Договора:</u></em></strong></p>
<p>1) Стороны пришли к соглашению о расторжении Договора в соответствии с пунктом _____ Договора <em><u>(указывается основание для расторжения</u></em><em><u> Договора</u></em><em><u>)</u></em>.</p>
<p>&nbsp;</p>
<p>1.2 Соглашение является неотъемлемой частью Договора.</p>
<p>1.3 Все остальные условия Договора в части, неизменной Соглашением, остаются в силе.</p>
<p>1.4 Соглашение вступает в силу со дня подписания и действует в течение срока действия Договора.</p>
<p>1.5 Соглашение составлено в двух экземплярах, на казахском и русском языках, имеющих одинаковую юридическую силу.</p>
<p>&nbsp;</p>
<p><strong>2. </strong><strong>Реквизиты сторон</strong></p>
<p><strong>Исполнитель</strong></p>
<p>${filial}</p>
<p>Адрес: ${filialDate?.addressLine}</p>
<p>БИН ${filialDate?.bin}</p>
<p>ИИК KZ ${filialDate?.bankAccountNumber}</p>
<p>АО</p>
<p>БИК ${filialDate?.bankBik}</p>
<p>КБе ${filialDate?.beneficiaryCode}</p>
<p>Тел ${filialDate?.phoneNumber}</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p><strong>Директор&nbsp;&nbsp;&nbsp; </strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>${supervisorName} <u>&nbsp;Ф.И.О.</u></strong></p>
<p>&nbsp;</p>
<p>М.П.</p>
<p><strong>Заказчик</strong></p>
<p>${client}</p>
<p>Адрес: ${addressLine}</p>
<p>БИН/ИИН ${bin ? bin : inn}</p>
<p>Банк ${bank ? bank : "Не задано"}</p>
<p>ИИК ${bankAccountNumber ? bankAccountNumber : "Не задано"}</p>
<p>БИК ${bankBik? bankBik : "Не задано"}</p>
<p>Кбе ${clientData?.beneficiaryCode ? clientData?.beneficiaryCode : "Не задано"}</p>
<p>&nbsp;</p>
<p>Тел. &nbsp${phoneNumber}</p>
<p>&nbsp;</p>
<p><strong>Директор</strong></p>
<p><strong>${supervisorName}</strong></p>
<p>М.П.</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`
